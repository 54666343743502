import { Injectable } from '@angular/core';
import { IFileSettings } from '@core/interfaces';
import { blobToBase64 } from '@core/utils';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { DocumentsService } from './documents.service';

@Injectable()
export class FileFormService {
  constructor(private readonly documentSvc: DocumentsService) {}

  filesRequest$(body = {}, isLoadFile = false): Observable<IFileSettings[]> {
    return this.documentSvc.userFileSettings(body).pipe(
      switchMap((data) => {
        const obs$ = data.map((item) => {
          const lastUploadedFileId = (item?.userFiles ?? [])[0]?.file_id;
          if (lastUploadedFileId && isLoadFile) {
            return this.getUserProfileFile$(lastUploadedFileId).pipe(
              map((file) => ({
                ...item,
                lastUploadedPhoto: file,
                lastUploadedFileId,
              })),
              // 53071  // 56641
              catchError(() =>
                of({
                  ...item,
                  lastUploadedPhoto: null,
                  lastUploadedFileId: lastUploadedFileId ?? null,
                }),
              ),
            );
          }
          return of({ ...item, lastUploadedPhoto: null, lastUploadedFileId: lastUploadedFileId ?? null });
        });
        return forkJoin(obs$);
      }),
    );
  }

  private getUserProfileFile$(id: number) {
    return this.documentSvc
      .userFileView({ user_profile_file_id: id })
      .pipe(switchMap((data: Blob) => blobToBase64(data)));
  }
}
