import { Injectable } from '@angular/core';
import { IResponseData, IUserCashback } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CashbackService {
  constructor(private readonly apiSvc: ApiService) {}

  withdrawToWallet(body = {}): Observable<IResponseData<unknown>> {
    return this.apiSvc.post('user/user-cashback/payout-on-payment-instance', body);
  }

  payoutOnCredit(body = {}) {
    return this.apiSvc.post('user/user-cashback/payout-on-credit', body);
  }

  sendToUser(body = {}) {
    return this.apiSvc.post('user/user-cashback/send-to-user', body);
  }

  buyTrx(body = {}) {
    return this.apiSvc.post('user/user-cashback/buy-trx', body);
  }

  list(body = {}): Observable<IResponseData<IUserCashback[]>> {
    return this.apiSvc.post('user/user-cashback/list', body);
  }
}
