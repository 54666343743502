export enum UserCashbackReasonEnum {
  CREDIT = 1,
  ENTERING_EMPLOYEE = 2,
  CANCELLATION = 3,
  ENTERING_CHS = 4,
  RECHARGE_PHONE = 5,
  UBKI = 6,
  PAYOUT_ON_PAYMENT_INSTANCE = 7,
  ENTERING_EMPLOYEE_SORRY_BONUS = 8,
  ENTERING_EMPLOYEE_PROMOTIONAL_OFFERS = 9,
  ENTERING_EMPLOYEE_PROMOTIONALS = 10,
  ENTERING_EMPLOYEE_SORRY_GIVEAWAYS = 11,
  ENTERING_EMPLOYEE_SPECIAL_OFFERS = 12,
  TRANSFER_BETWEEN_USERS = 13,
  BUY_TRX = 14,
}
