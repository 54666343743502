import { Injectable } from '@angular/core';
import { IResponseData } from '@core/interfaces';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EcpService {
  constructor(private readonly apiSvc: ApiService) {
  }

  privateBankEcp(id: number | string): Observable<IResponseData<{ qr_code: string; redirect_url: string }>> {
    return this.apiSvc.post('user/application/sign-credit-agreement', { id });
  }
}
