// INCOME AMOUNT VALIDATOR;
export const MIN_INCOME_AMOUNT = 1000;
export const MAX_INCOME_AMOUNT = 999999999;

// ADDITIONAL INCOME AMOUNT VALIDATOR
export const MIN_ADDITIONAL_INCOME_AMOUNT = 1000;
export const MAX_ADDITIONAL_INCOME_AMOUNT = 999999999;

// EMPTY DATE
export const EMPTY_DATE = '0000-00-00';
