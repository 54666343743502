export enum PersonalDataControlsType {
  LastName = 'last_name',
  FirstName = 'first_name',
  ThirdName = 'third_name',
  LastNameChange = 'last_name_change',
  LastNamePrev = 'last_name_prev',
  BirthDate = 'birth_date',
  Inn = 'inn',
  Sex = 'sex',
  PassportType = 'passport_type',
  PassportSeries = 'passport_series',
  PassportIdRecordNumber = 'passport_id_record_number',
  PassportNumber = 'passport_number',
  PassportWho = 'passport_who',
  PassportDate = 'passport_date',
  PassportIdExpire = 'passport_id_expire',
  Education = 'education',
  MonthlyPaymentsOnExistingLoans = 'monthly_payments_on_existing_loans',
  LoanPurpose = 'loan_purpose',
  APhone = 'a_phone',
}
