export function getRegionFilters(code: string, value = '', id?: number) {
  const functionOr = [
    {
      check: '=',
      key: 'country',
      value: code,
    },
    {
      check: '=',
      key: 'is_moderated',
      value: true,
    },
    {
      check: '=',
      key: 'is_hidden',
      value: false,
    },
  ];
  const where = id ? [...functionOr, { check: '=', key: 'id', value: id }] : [...functionOr];
  return {
    alias: 'rl',
    filters: {
      function: 'and',
      where: [
        {
          function: 'or',
          where: [
            {
              check: 'ILIKE',
              key: 'international_name',
              value,
            },
            {
              check: 'ILIKE',
              key: 'original_name',
              value,
            },
          ],
        },
        {
          function: 'and',
          where: [...where],
        },
      ],
    },
    pagination: { pageSize: 0 },
  };
}

export function getDistrictFilters(regionId: number, value = '', id?: number) {
  const functionAnd = [
    {
      check: '=',
      key: 'address_region_id',
      value: regionId,
    },
    {
      check: '=',
      key: 'is_hidden',
      value: false,
    },
  ];
  const where = id ? [...functionAnd, { check: '=', key: 'id', value: id }] : functionAnd;
  return {
    alias: 'dl',
    filters: {
      function: 'and',
      where: [
        {
          function: 'or',
          where: [
            {
              check: 'ILIKE',
              key: 'international_name',
              value,
            },
            {
              check: 'ILIKE',
              key: 'original_name',
              value,
            },
          ],
        },
        {
          function: 'and',
          where: [...where],
        },
      ],
    },
    pagination: { pageSize: 0 },
  };
}

export function getCityFilters(districtId: number, value = '', id?: number) {
  const functionAnd = [
    {
      check: '=',
      key: 'is_hidden',
      value: false,
    },
    {
      check: '=',
      key: 'address_district_id',
      value: districtId,
    },
  ];
  const where = id
    ? [
        ...functionAnd,
        {
          check: '=',
          key: 'id',
          value: id,
        },
      ]
    : [...functionAnd];
  return {
    alias: 'cl',
    filters: {
      function: 'and',
      where: [
        {
          function: 'or',
          where: [
            {
              check: 'ILIKE',
              key: 'international_name',
              value,
            },
            {
              check: 'ILIKE',
              key: 'original_name',
              value,
            },
          ],
        },
        {
          function: 'and',
          where: [...where],
        },
      ],
    },
    pagination: { pageSize: 0 },
  };
}

export function getStreetFilters(cityId: number, value = '', id?: number) {
  const functionAnd = [
    {
      check: '=',
      key: 'is_hidden',
      value: false,
    },
    {
      check: '=',
      key: 'address_city_id',
      value: cityId,
    },
  ];
  const where = id
    ? [
        ...functionAnd,
        {
          check: '=',
          key: 'id',
          value: id,
        },
      ]
    : [...functionAnd];

  return {
    filters: {
      function: 'and',
      where: [
        {
          function: 'or',
          where: [
            {
              check: 'ILIKE',
              key: 'international_name',
              value,
            },
            {
              check: 'ILIKE',
              key: 'original_name',
              value,
            },
          ],
        },
        {
          function: 'and',
          where: [...where],
        },
      ],
    },
    pagination: { pageSize: 0 },
  };
}

export function getHouseFilters(streetId: number, value = '', id?: number) {
  const functionAnd = [
    {
      check: '=',
      key: 'address_street_id',
      value: streetId,
    },
    {
      check: '=',
      key: 'is_hidden',
      value: false,
    },
  ];
  const where = id
    ? [
        ...functionAnd,
        {
          check: '=',
          key: 'id',
          value: id,
        },
      ]
    : [...functionAnd];

  return {
    filters: {
      function: 'and',
      where: [
        {
          function: 'or',
          where: [
            {
              check: 'ILIKE',
              key: 'name',
              value,
            },
          ],
        },
        {
          function: 'and',
          where: [
            {
              function: 'and',
              where: [...where],
            },
          ],
        },
      ],
    },
    pagination: { pageSize: 0 },
  };
}

export function getFlatFilters(houseId: number, value = '', id?: number) {
  const functionAnd = [
    {
      check: '=',
      key: 'address_house_id',
      value: houseId,
    },
    {
      check: '=',
      key: 'is_hidden',
      value: false,
    },
  ];
  const where = id
    ? [
        ...functionAnd,
        {
          check: '=',
          key: 'id',
          value: id,
        },
      ]
    : [...functionAnd];

  return {
    filters: {
      function: 'and',
      where: [
        {
          function: 'or',
          where: [
            {
              check: 'ILIKE',
              key: 'name',
              value,
            },
          ],
        },
        {
          function: 'and',
          where: [
            {
              function: 'and',
              where: [...where],
            },
          ],
        },
      ],
    },
    pagination: { pageSize: 0 },
  };
}
