import { Injectable } from '@angular/core';
import { IPreviewExchange, IResponseData } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ExchangeService {
  constructor(private readonly apiSvc: ApiService) {}

  previewExchange(body = {}): Observable<IResponseData<IPreviewExchange>> {
    return this.apiSvc.post('user/integration-payment-system/preview-exchange', body);
  }

  exchange(body = {}): Observable<IResponseData<void>> {
    return this.apiSvc.post('user/integration-payment-system/exchange', body);
  }

  requestExchange(body = {}): Observable<IResponseData<void>> {
    return this.apiSvc.post('user/integration-payment-system/request-exchange', body);
  }
}
