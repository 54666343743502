import { Injectable } from '@angular/core';
import { STORAGE_CONSTANT } from '@core/constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserLogIdService {
  private readonly _userLogId$ = new BehaviorSubject<number | null>(null);

  constructor(private readonly storageSvc: StorageService) {
    const logId = this.storageSvc.get<number | null>(STORAGE_CONSTANT.LOG_ID) as number;
    if (logId) this.userLogId = logId;
  }

  get userLogId$(): Observable<number | null> {
    return this._userLogId$.asObservable();
  }

  set userLogId(id: number | null) {
    this._userLogId$.next(id);
  }
}
