export type compareValueType = string | number | boolean;

export function compareLess(controlValue: compareValueType, compareValue: compareValueType): boolean {
  return controlValue < compareValue;
}

export function compareLessOrEqual(controlValue: compareValueType, compareValue: compareValueType): boolean {
  return controlValue <= compareValue;
}

export function compareBigger(controlValue: compareValueType, compareValue: compareValueType): boolean {
  return controlValue > compareValue;
}

export function compareBiggerOrEqual(controlValue: compareValueType, compareValue: compareValueType): boolean {
  return controlValue >= compareValue;
}

export function compareStrictEquality(controlValue: compareValueType, compareValue: compareValueType): boolean {
  return controlValue === compareValue;
}

export function compareNotEqual(controlValue: compareValueType, compareValue: compareValueType): boolean {
  return controlValue != compareValue;
}

export function compareStrictNotEqual(controlValue: compareValueType, compareValue: compareValueType): boolean {
  return controlValue !== compareValue;
}

export function compareBetweenValues(controlValue: compareValueType, compareValue: Array<compareValueType>): boolean {
  return controlValue > compareValue[0] && controlValue < compareValue[1];
}

export function compareBetweenValuesOrEqualOneOfThem(
  controlValue: compareValueType,
  compareValue: Array<compareValueType>,
): boolean {
  return (
    (controlValue > compareValue[0] && controlValue < compareValue[1]) ||
    controlValue === compareValue[0] ||
    controlValue === compareValue[1]
  );
}

export function compareBetweenValuesOrEqualToLeft(
  controlValue: compareValueType,
  compareValue: Array<compareValueType>,
): boolean {
  return (controlValue > compareValue[0] && controlValue < compareValue[1]) || controlValue === compareValue[0];
}

export function compareBetweenValueOrEqualToRight(
  controlValue: compareValueType,
  compareValue: Array<compareValueType>,
): boolean {
  return (controlValue > compareValue[0] && controlValue < compareValue[1]) || controlValue === compareValue[1];
}

export function comparePresentInTheList(controlValue: compareValueType, list: Array<{ value: unknown }>): boolean {
  const item = list?.find((item) => item?.value === controlValue);
  return !!item;
}

export function compareNotPresentInTheList(controlValue: compareValueType, list: Array<{ value: unknown }>): boolean {
  const item = list?.find((item) => item?.value === controlValue);
  return !item;
}

export function compareLengthMoreThan(controlValue: compareValueType, compareValue: compareValueType): boolean {
  return controlValue?.toString()?.length > compareValue;
}

export function compareLengthLessThan(controlValue: compareValueType, compareValue: compareValueType): boolean {
  return controlValue?.toString()?.length < compareValue;
}

export function compareLengthEqual(controlValue: compareValueType, compareValue: compareValueType): boolean {
  return controlValue?.toString()?.length === compareValue;
}
