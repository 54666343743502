export enum WorkTypeEnum {
  /** Работающий официально */
  Working = '1',
  /** Безработный */
  Unemployed = '2',
  /** Студент */
  Student = '3',
  /** Пособие / Пенсия по инвалидности / Декретный отпуск*/
  Benefit = '4',
  /** Самозанятое лицо / Предприниматель */
  SelfEmployed = '5',
  /** Пенсионер*/
  Pensioner = '5',
  /** */
  WorkingInformally = '7',
  /** Военнослужащий */
  Military = '8',
}
