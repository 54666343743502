export enum SurveyTypeEnum {
  /** Опросник для админки.*/
  Admin = 1,
  /** Опрос пользователей о качестве обслуживания.*/
  QualityOfService = 2,
  /** Опрос пользователей при закрытии кредита.*/
  ClosedCredit = 3,
  /** Опрос пользователей при открытии кредита.*/
  OpenCredit = 4,
}
