import { Injectable } from '@angular/core';
import { IResponseData } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FinalizationService {
  constructor(private readonly apiSvc: ApiService) {}

  secretQuestion(body = {}): Observable<IResponseData<{ success: boolean }>> {
    return this.apiSvc.post('online/public-api/credit/answer-to-secret-question-for-revision', body);
  }

  changeRevisionReason(body = {}) {
    return this.apiSvc.post('online/public-api/credit/change-revision-reason', body);
  }

  setToPhoneCallBack(body = {}): Observable<void> {
    return this.apiSvc.post('online/public-api/borrower/set-to-queue-autodialer-telemarketing-callback', body);
  }

  isVipClient(body = {}): Observable<IResponseData<{ is_vip: boolean }>> {
    return this.apiSvc.post('online/api/user/get-is-vip-user-by-phone', body);
  }

  videoCall(): Observable<void> {
    return this.apiSvc.post('user/video-call/add');
  }
}
