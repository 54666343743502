import { Injectable } from '@angular/core';
import { IResponseData } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(private readonly apiSvc: ApiService) {}

  confirm(body = {}): Observable<IResponseData<unknown>> {
    return this.apiSvc.post('online/public-api/credit/confirm-contract', body);
  }

  code(body = {}): Observable<IResponseData<{ code: string; contact: { id: string; value: string } }>> {
    return this.apiSvc.post('online/public-api/credit/confirm-contract-code', body);
  }

  notifyForSignCreditAgreement(body = {}): Observable<IResponseData<{ code: string }>> {
    return this.apiSvc.post('user/application/notify-for-sign-credit-agreement', body);
  }

  signCreditAgreementBySms(body = {}): Observable<IResponseData<void>> {
    return this.apiSvc.post('user/application/sign-credit-agreement-by-sms', body);
  }
}
