import { Injectable } from '@angular/core';
import { IContact, IdData, IResponseData } from '@core/interfaces';
import { parseContact } from '@core/utils';
import { map, Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UserContactsService {
  constructor(private readonly apiSvc: ApiService) {}

  list(body = {}): Observable<IContact[]> {
    return this.apiSvc.post<IResponseData<IContact[]>>('user/user-phone/list', body).pipe(
      map((r: IResponseData<IContact[]>) => r?.data ?? []),
      map((data: IContact[]) => {
        return data.map((contact) => ({
          ...contact,
          phone_number: parseContact(contact.phone),
        }));
      }),
    );
  }

  addContact(body = {}): Observable<IResponseData<IdData>> {
    return this.apiSvc.post('user/user-phone/add', body);
  }

  verifyContact(body = {}): Observable<IResponseData<void>> {
    return this.apiSvc.post('user/user-phone/verify-phone', body);
  }

  get(body = {}): Observable<IContact> {
    return this.apiSvc.post<IResponseData<IContact>>('user/user-phone/get', body).pipe(map((r) => r?.data));
  }

  repeatVerifyCode(body = {}): Observable<IResponseData<void>> {
    return this.apiSvc.post('user/user-phone/repeat-verification-code', body);
  }
}
