import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, EMPTY, of, switchMap, timer } from 'rxjs';
import { StorageService } from '../';
import * as moment from 'moment';

@UntilDestroy()
@Injectable()
export class IntervalService {
  private _storageKey = '';
  set storageKey(value: string) {
    this._storageKey = value;
  }

  get storageKey(): string {
    return this._storageKey;
  }

  get storageTimeStampKey(): string {
    return this._storageKey + '__timestamp';
  }

  get initialIntervalValue(): number | null {
    return this.storageKey ? this.storageSvc.get<number>(this.storageKey) : null;
  }

  get initialTimestampValue(): number | null {
    return this.storageKey ? this.storageSvc.get<number>(this.storageTimeStampKey) : null;
  }

  private readonly interval$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  readonly isFinishedIntervalSub$ = new BehaviorSubject<boolean>(false);

  readonly intervalSub$ = this.interval$.pipe(
    switchMap(() =>
      timer(0, 1000).pipe(
        switchMap(() => {
          if (!this.timerData || this.timerData <= 0) return EMPTY;
          this.isFinishedIntervalSub$.next(!(this.timerData - 1));
          return of((this.timerData -= 1));
        }),
      ),
    ),
  );

  private timerData = 0;

  constructor(private readonly storageSvc: StorageService) {
  }

  init(storageKey?: string): void {
    this.storageKey = storageKey ? storageKey : '';
    const initialIntervalValue = (this.initialIntervalValue ?? 0) - moment().diff(moment(this.initialTimestampValue ?? new Date()), 'second');
    this.timerData = initialIntervalValue ?? 0;
    if (initialIntervalValue) this.interval$.next(initialIntervalValue);
  }

  startInterval(intervalValue = 0): void {
    if (this.storageKey) {
      this.storageSvc.set<number>(this.storageKey, intervalValue);
      this.storageSvc.set<number>(this.storageTimeStampKey, new Date().getTime());
    }
    this.timerData = intervalValue;
    this.interval$.next(intervalValue);
  }
}
