import { Injectable } from '@angular/core';
import { CreditStatus, DocumentTemplateTypeEnum } from '@core/enums';
import {
  IApplication,
  ICheckCreateCredit,
  ICodeData,
  ICredit,
  ICreditRevision,
  IdData,
  IPreviewIncreaseAmount,
  IResponseData,
  SoldInfo,
} from '@core/interfaces';
import { map, Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CreditService {
  constructor(private readonly apiSvc: ApiService) {}

  applicationsForGuard(): Observable<IResponseData<IApplication[]>> {
    const body = {
      expand: ['credit'],
      with: [
        {
          name: 'credit',
          alias: 'apc',
          join_type: 'LEFT JOIN',
        },
      ],
      sort: { id: 'DESC' },
    };
    return this.apiSvc.post('user/application/list', body);
  }

  soldApplication(): Observable<IApplication | null> {
    const body = {
      expand: [
        'credit',
        'credit.oldCreditCreditFactoringDealItem',
        'credit.oldCreditCreditFactoringDealItem.creditFactoringDeal',
        'credit.oldCreditCreditFactoringDealItem.creditFactoringDeal.toBrand',
        'credit.oldCreditCreditFactoringDealItem.creditFactoringDeal.toBrand',
        'credit.oldCreditCreditFactoringDealItem.creditFactoringDeal.toBrand.phone',
        'credit.oldCreditCreditFactoringDealItem.creditFactoringDeal.toBrand.email',
        'credit.oldCreditCreditFactoringDealItem.creditFactoringDeal.toBrand.addressCity',
        'credit.oldCreditCreditFactoringDealItem.creditFactoringDeal.toBrand.addressStreet',
        'credit.oldCreditCreditFactoringDealItem.creditFactoringDeal.toBrand.addressHouse',
        'credit.oldCreditCreditFactoringDealItem.creditFactoringDeal.toBrand.addressFlat',
      ],
      with: [
        {
          name: 'credit',
          alias: 'apc',
          join_type: 'LEFT JOIN',
          with: [
            {
              name: 'oldCreditCreditFactoringDealItem',
              alias: 'occfdi',
              join_type: 'LEFT JOIN',
              with: [
                {
                  name: 'creditFactoringDeal',
                  alias: 'cfddfddf',
                  join_type: 'LEFT JOIN',
                  with: [
                    {
                      name: 'toBrand',
                      alias: 'cfdtb',
                      join_type: 'LEFT JOIN',
                      with: [
                        {
                          name: 'phone',
                          alias: 'tbp',
                          join_type: 'LEFT JOIN',
                        },
                        {
                          name: 'email',
                          alias: 'tbe',
                          join_type: 'LEFT JOIN',
                        },
                        {
                          name: 'addressCity',
                          alias: 'tbac',
                          join_type: 'LEFT JOIN',
                        },
                        {
                          name: 'addressStreet',
                          alias: 'tbas',
                          join_type: 'LEFT JOIN',
                        },
                        {
                          name: 'addressHouse',
                          alias: 'tbah',
                          join_type: 'LEFT JOIN',
                        },
                        {
                          name: 'addressFlat',
                          alias: 'tbaf',
                          join_type: 'LEFT JOIN',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
    return this.apiSvc.post<IResponseData<IApplication[]>>('user/application/list', body).pipe(
      map((r) => r.data ?? []),
      map((data) => data?.find((app) => app?.credit?.status === CreditStatus.Sold) || null),
    );
  }

  applications(filters = {}, expand: string[] = []): Observable<IResponseData<IApplication[]>> {
    const body = {
      expand: [
        'userPaymentInstance',
        'userPaymentInstance.userPaymentInstanceUuids',
        'credit',
        'credit.commissions_accrual',
        'credit.commissions_payed',
        'credit.commissions_write_off',
        'credit.lastCreditCalculation',
        'credit.schedule_amount',
        'credit.lastCreditCalculation.left_for_close_credit',
        'credit.lastCreditCalculation.creditCalculationData',
        'creditProduct',
        'creditProduct.creditProductLocales',
        'credit.available_prolongations',
        'credit.oldCreditCreditFactoringDealItem',
        'credit.oldCreditCreditFactoringDealItem.creditFactoringDeal',
        'credit.oldCreditCreditFactoringDealItem.creditFactoringDeal.toBrand',
        'traffic_link',
        ...expand,
      ],
      filters: { ...filters },
      with: [
        {
          name: 'userPaymentInstance',
          alias: 'upi',
          join_type: 'LEFT JOIN',
          with: [
            {
              name: 'userPaymentInstanceUuids',
              alias: 'upi_uid',
              join_type: 'LEFT JOIN',
            },
          ],
        },
        {
          name: 'credit',
          alias: 'apc',
          join_type: 'LEFT JOIN',
          with: [
            {
              name: 'lastCreditCalculation',
              alias: 'apc_lcc',
              join_type: 'LEFT JOIN',
              with: [
                {
                  name: 'creditCalculationData',
                  alias: 'apc_lcc_ccd',
                  join_type: 'LEFT JOIN',
                },
              ],
            },
            {
              name: 'oldCreditCreditFactoringDealItem',
              alias: 'occfdi',
              join_type: 'LEFT JOIN',
              with: [
                {
                  name: 'creditFactoringDeal',
                  alias: 'cfddfddf',
                  join_type: 'LEFT JOIN',
                  with: [
                    {
                      name: 'toBrand',
                      alias: 'cfdtb',
                      join_type: 'LEFT JOIN',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'creditProduct',
          alias: 'cp_app',
          join_type: 'LEFT JOIN',
          with: [
            {
              name: 'creditProductLocales',
              alias: 'cpl_app',
              join_type: 'LEFT JOIN',
            },
          ],
        },
      ],
      sort: { id: 'DESC' },
    };
    return this.apiSvc.post('user/application/list', body);
  }

  create(body = {}): Observable<IResponseData<IdData>> {
    return this.apiSvc.post('user/application/create', body);
  }

  application(id: string | number): Observable<IResponseData<IApplication>> {
    // долгосрочный schedule_settings.items?.length - долгосрочный ли кредит
    const body = {
      id,
      expand: [
        'userPaymentInstance',
        'userPaymentInstance.userPaymentInstanceUuids',
        'credit',
        'credit.commissions_accrual',
        'credit.commissions_payed',
        'credit.commissions_write_off',
        'credit.lastCreditCalculation',
        'credit.lastCreditCalculation.left_for_close_credit',
        'credit.lastCreditCalculation.creditCalculationData',
        'credit.creditProlongation',
        'credit.creditRestructuring',
        'credit.creditRestructuring',
        'credit.schedule_amount',
        'credit.creditRestructuring.creditSchedules',
        'creditProduct',
        'creditProduct.creditProductLocales',
        'credit.available_prolongations',
        'documents',
        'documents.documentTemplate',
      ],
      with: [
        {
          name: 'userPaymentInstance',
          alias: 'upi',
          join_type: 'LEFT JOIN',
          with: [
            {
              name: 'userPaymentInstanceUuids',
              alias: 'upi_uid',
              join_type: 'LEFT JOIN',
            },
          ],
        },
        {
          name: 'documents',
          alias: 'upiaaaa',
          join_type: 'LEFT JOIN',
          with: [
            {
              name: 'documentTemplate',
              alias: 'upidt',
              join_type: 'LEFT JOIN',
              filters: {
                function: 'and',
                where: [
                  {
                    check: '=',
                    key: 'type',
                    value: DocumentTemplateTypeEnum.CreditPassport,
                  },
                ],
              },
            },
          ],
        },
        {
          name: 'credit',
          alias: 'apc',
          join_type: 'LEFT JOIN',
          with: [
            {
              name: 'creditProlongation',
              alias: 'cp_cpr',
              join_type: 'LEFT JOIN',
            },
            {
              name: 'creditRestructuring',
              alias: 'cp_crt',
              join_type: 'LEFT JOIN',
              with: [
                {
                  name: 'creditSchedules',
                  alias: 'cr_csh',
                  join_type: 'LEFT JOIN',
                },
              ],
            },
            {
              name: 'lastCreditCalculation',
              alias: 'apc_lcc',
              join_type: 'LEFT JOIN',
              with: [
                {
                  name: 'creditCalculationData',
                  alias: 'apc_lcc_ccd',
                  join_type: 'LEFT JOIN',
                },
              ],
            },
          ],
        },
        {
          name: 'creditProduct',
          alias: 'cp_app',
          join_type: 'LEFT JOIN',
          with: [
            {
              name: 'creditProductLocales',
              alias: 'cpl_app',
              join_type: 'LEFT JOIN',
            },
          ],
        },
      ],
    };
    return this.apiSvc.post('user/application/get', body);
  }

  checkCredit(id: number): Observable<IResponseData<ICreditRevision[]>> {
    const body = {
      alias: 'arl',
      expand: ['revisionReason', 'revisionReason.revisionReasonLocales'],
      with: [
        {
          alias: 'rr',
          name: 'revisionReason',
          join_type: 'LEFT JOIN',
        },
        {
          alias: 'rrl',
          name: 'revisionReason.revisionReasonLocales',
          join_type: 'LEFT JOIN',
        },
      ],
      filters: {
        function: 'and',
        where: [
          {
            check: '=',
            key: 'application_id',
            value: id,
          },
          {
            check: '=',
            key: 'is_active',
            value: true,
          },
          {
            check: '=',
            key: 'is_fixed',
            value: false,
          },
        ],
      },
    };
    return this.apiSvc.post('user/application-revision/list', body);
  }

  prolongation(body = {}): Observable<IResponseData<void>> {
    return this.apiSvc.post('online/public-api/credit/long', body);
  }

  checkCreateCredit(body = {}): Observable<IResponseData<ICheckCreateCredit>> {
    return this.apiSvc.post('user/application/can-create-application', body);
  }

  increaseAmount(body = {}): Observable<IResponseData<unknown>> {
    return this.apiSvc.post('user/application/increase-amount', body);
  }

  soldCreditInfo(body = {}): Observable<IResponseData<SoldInfo>> {
    return this.apiSvc.post('online/public-api/credit-deal/get-by-credit-id', body);
  }

  getContractCode(body = {}): Observable<IResponseData<{ code: string }>> {
    return this.apiSvc.post('user/application/notify-for-sign-credit-agreement', body);
  }

  signContract(body = {}): Observable<IResponseData<unknown>> {
    return this.apiSvc.post('user/application/sign-credit-agreement-by-sms', body);
  }

  getCreditPassportCode(body = {}): Observable<IResponseData<ICodeData>> {
    return this.apiSvc.post('user/application/notify-for-sign-credit-passport', body);
  }

  signCreditPassport(body = {}) {
    return this.apiSvc.post('user/application/sign-credit-passport-by-sms', body);
  }

  previewIncreaseAmount(body = {}): Observable<IResponseData<IPreviewIncreaseAmount>> {
    return this.apiSvc.post('user/application/preview-increase-amount', body);
  }

  creditProlongation(body = {}): Observable<IResponseData<unknown>> {
    return this.apiSvc.post('user/credit/prolongation', body);
  }

  update(body = {}): Observable<IResponseData<IdData>> {
    return this.apiSvc.post('user/application/update', body);
  }

  creditList(body = {}): Observable<IResponseData<ICredit[]>> {
    return this.apiSvc.post('user/credit/list', body);
  }

  changePaymentInstance(body = {}) {
    return this.apiSvc.post('user/application/change-payment-instance', body);
  }

  credit(id: number): Observable<ICredit> {
    return this.apiSvc.post<IResponseData<ICredit>>('user/credit/get', { id }).pipe(map((r) => r?.data));
  }
}
