import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@core/environment';
import { IdData, IEnvironment, IFileSettings, IPrivateBankSing, IResponseData } from '@core/interfaces';
import { map, Observable, tap } from 'rxjs';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { ProfileAvatarStore } from '@core/state';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(
    private readonly apiSvc: ApiService,
    private readonly httpClient: HttpClient,
    @Inject(ENVIRONMENT) private readonly env: IEnvironment,
    private readonly profileAvatarStore: ProfileAvatarStore,
    private readonly userSvc: UserService,
  ) {
  }

  userFileSettings(body = {}): Observable<IFileSettings[]> {
    return this.apiSvc
      .post<IResponseData<IFileSettings[]>>('user/user-file-setting/list', body)
      .pipe(map((r) => r?.data ?? []));
  }

  userFileUpload(body = {}): Observable<IdData> {
    return this.apiSvc.upload<IResponseData<IdData>>('user/user-profile-file/upload', body).pipe(map((r) => r?.data));
  }

  setProfilePhoto(body = {}): Observable<IResponseData<string>> {
    return this.apiSvc.upload<IResponseData<string>>('user/user/set-avatar', body).pipe(
      tap(() => this.profileAvatarStore.setHasCache(false)),
    );
  }

  signedFileUpload(body = {}): Observable<unknown> {
    return this.apiSvc
      .upload<IResponseData<IdData>>('user/user-profile-file/upload-signed-file', body)
      .pipe(map((r) => r?.data));
  }

  userFileView(body = {}): Observable<Blob> {
    return this.apiSvc.post<Blob>('user/user-profile-file/view', body, 'blob');
  }

  importStandardUbkiReport(body = {}): Observable<IResponseData<unknown>> {
    return this.apiSvc.upload('user/integration-ubki/import-standard-report', body);
  }

  checkExistUbkiReport(): Observable<IResponseData<{ exist: boolean }>> {
    return this.apiSvc.post('user/integration-ubki/check-exist-report');
  }

  getProfileSetting(): Observable<IFileSettings[]> {
    return this.apiSvc
      .post<IResponseData<IFileSettings[]>>('user/user-profile-file/list')
      .pipe(map((r) => r?.data ?? []));
  }

  privateBankSignDocuments(): Observable<IResponseData<IPrivateBankSing>> {
    return this.apiSvc.post('user/user/sign-documents-by-private-bank');
  }


  requestUbki(body = {}): Observable<IResponseData<unknown>> {
    return this.apiSvc.upload('user/integration-ubki/request-ubki', body);
  }

  enterCodeUbki(body = {}): Observable<IResponseData<unknown>> {
    return this.apiSvc.upload('user/integration-ubki/enter-code-ubki ', body);
  }
}
