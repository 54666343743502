import { Inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ICONS } from '@core/icons';
import { Icon } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private icons: Icon[] = [];

  constructor(private readonly matIconRegistry: MatIconRegistry, private readonly domSanitizer: DomSanitizer,
              @Inject(ICONS) icons: Icon[]) {
    this.icons = icons;
  }

  set addIcon(icon: Icon) {
    this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path));
  }

  init(): void {
    this.setIcons();
  }

  private setIcons(): void {
    this.icons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(icon?.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path));
    });
  }
}
