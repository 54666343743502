export const ERROR_MESSAGE: Record<string, string> = {
  required: 'ERRORS.required',
  upperCaseCharacter: 'ERRORS.upperCaseCharacter',
  latinCharacters: 'ERRORS.latinCharacters',
  numericCharacter: 'ERRORS.numericCharacter',
  invalidPassword: 'ERRORS.invalidPassword',
  invalidInn: 'ERRORS.invalidInn',
  onlyCyrillic: 'ERRORS.onlyCyrillic',
  minlength: 'ERRORS.minlength',
  invalidPhone: 'ERRORS.invalidPhone',
  duplicatePhone: 'ERRORS.duplicatePhone',
  invalidTaxNumber: 'ERRORS.invalidTaxNumber',
  duplicateTaxNumber: 'ERRORS.duplicateTaxNumber',
  invalidPasswordFormat: 'ERRORS.invalidPasswordFormat',
  invalidName: 'ERRORS.invalidName',
  invalidSurname: 'Невірне призвище',
  invalidRecordNumber: 'ERRORS.invalidRecordNumber',
  passwordsMismatch: 'Паролі мають співпадати',
  maxlength: 'Пароль занадто великий',
  lowerCaseCharacter: 'Пароль має містити хоча б одну маленьку літеру',
  remindPasswordPhoneInformationNotFound: 'Інформацію не знайдено',
  remindPasswordInvalidCodeValue: 'Невірний код',
  remindPasswordInvalidFormat: 'Невірний формат паролю',
  remindPasswordTooShort: 'Значення за коротке',
  remindPasswordTooLong: 'Значення за довге',
  remindPasswordNeedNumber: 'Пароль має містити число',
  remindPasswordBothCase: 'Пароль має включати літери ',
  remindPasswordInvalidCode: 'Не вірний код',
  remindPasswordInformationNotFound: 'Інформацію не знайдено',
  remindPasswordTooManyCodes: 'Забагато запитів на отримання коду',
  remindPasswordIntervalBetweenCode: 'Забагато запитів на отримання коду',
  matDatepickerParse: 'Значення дати не валідне',
  invalidPassportIssueBy: 'Значення не вірне',

  newPhoneInvalidFormat: 'Невірний телефон',
  min: 'Значення занадто мале',
  max: 'Значення занадто велике',

  // LOGIN
  loginCodeToManyRequests: 'Забагато кодів запрошено за останній час',
  loginCodeRequestedToOften: 'Код запитується занадто часто', //

  // USER AUTH
  userAuthInfoNotFound: 'Інформацію не знайдено', // Информация не найдена
  userAuthPhoneRequired: 'Поле обовʼязкове', // Отсутствует обязательный(ые) ключ(и) "phone"
  userAuthPasswordRequired: 'Поле обовʼязкове', // Отсутствует обязательный(ые) ключ(и) "password"
  userAuthInvalidPassword: 'Пароль не вірний', // Не правильный пароль
  userAuthCodeRequestedToOften: 'Забагато кодів запрошено за останній час', // Код авторизации запрашивается слишком часто
  userAuthTooManyCodes: 'Код запитується занадто часто', // Слишком много кодов запрошено за последнее время
  userAuthBlockedUser: 'Можливість входу заблокована', // Возможность входа для пользователя заблокирована
  userAuthWebsocketNotAvailable: 'Вебсокет недоступний', // Вебсокет недоступен

  // USER RECOVERY
  userRecoveryOldPhoneValueIsNotValid: 'Значення старого телефону не валідне',
  userRecoveryNewPhoneValueIsNotValid: 'Значення нового телефону не валідне',

  // REGISTRATION
  innIsNotUniq: 'Цей ІПН вже зареєстрований',
  registrationInvalidPhoneNumber: 'Невірний  номер телефону',
  registrationNumberAlreadyInUse: 'Цей номер вже зареєстрований',
  registrationPasswordTooLong: 'Пароль за довгий',
  registrationPasswordTooShort: 'Пароль за короткий',
  registrationPasswordNeedNumber: 'Пароль повинен містити цифри',
  registrationPasswordBothCase: 'Пароль має містити вкликі і малі літери',
  userRegistrationPasswordRequired: "Поле обов'язкове",
  userRegistrationPhoneRequired: "Поле обов'язкове",
  userRegistrationPhoneInvalid: 'Невірний  номер телефону',
  userRegistrationPhoneIsUsed: 'Цей номер вже зареєстрований',
  userRegistrationPasswordTooShort: 'Пароль за короткий',
  userRegistrationPasswordTooLong: 'Пароль за довгий',
  userRegistrationPasswordNeedNumber: 'Пароль повинен містити цифри',
  userRegistrationPasswordBothCase: 'Пароль має містити вкликі і малі літери',
  userRegistrationPasswordInvalidFormat: 'Значення не валідне',

  // REGISTRATION VERIFY
  registrationVerifyInformationNotFound: 'Інформація не знайдена',

  // PASSPORT DATA
  userProfileFileInvalidFileType: 'Невірний тип файлу',
  userProfileFileMissingFile: 'Файл відсутній',
  missingPassportNumber: 'ERRORS.required',
  missingPassportIssuedBy: 'ERRORS.required',
  missingPassportRecordNumber: 'ERRORS.required',
  invalidPassportSerial: 'Невірний номер',

  // REPEAT AUTH CODE
  repeatAuthCodeInfoNotFound: 'Інформацію не знайдено', // Информация не найдена
  repeatAuthCodeTooManyRequest: 'Код авторизації запитується дуже часто', // Код авторизации запрашивается слишком часто
  repeatAuthCodeTooManyRequestForLastTime: 'Забагато кодів запрошено за останній час', // Слишком много кодов запрошено за последнее время
  repeatAuthCodeUserBlocked: 'Можливість входу для користувача заблокована', // Возможность входа для пользователя заблокирована
  repeatAuthCodePasswordRequired: 'Виникла помилка', // Отсутствует обязательный(ые) ключ(и) "password"
  repeatAuthCodePasswordInvalid: 'Виникла помилка', // Значение ключа(ей) "password" невалидно
  repeatAuthCodePhoneRequired: 'Виникла помилка', // Значение ключа(ей) "password" невалидно

  // FORGOT PASSWORD

  // CALCULATOR CALCULATE
  calculatePromoCodeNotFound: 'Такого промокоду не існує',
  calculateAmountTooBig: 'Сума за велика',
  calculateAmountTooSmall: 'Сума за мала',
  calculateIntervalTooBig: 'Термін завеликий',
  calculateIntervalTooSmall: 'Термін замалий',
  valueMustBeChoosen: 'Виберіть значення зі списку',
  cpCalculatePromoCodeNotActive: 'Промокод не активний', // 	Значение ключа(ей) "promo_code" не активний
  cpCalculatePromoCodeUsed: 'Промокод вже використовуються', // 	Значение ключа(ей) "promo_code" уже используется
  cpCalculatePromoCodeWrongCreditProduct: 'Промокод не підходить до цього кредитного продукту', // 	Значение ключа(ей) "promo_code" не валидный для кп
  cpCalculatePromoCodeNotForClient: 'Промокод не валидний', // 	Значение ключа(ей) "promo_code" не валидный тип клиента
  cpCalculatePromoCodeWrongDate: 'Промокод дійсний лише у мобільному додатку', // 	Значение ключа(ей) "promo_code" не валидный тип клиента

  //ADD CREDIT CARD
  creditCardNumberRequired: 'Поле обовʼязкове',
  creditCardNumberTooLong: 'Значення завелике',
  creditCardVisaMasterCard: 'Карта має бути VISA або Mastercard',
  creditCardNumberUniq: 'Адреса гаманця вже існує',

  // NEW BACKEND
  // LOGIN
  loginInfoNotFound: 'ERRORS.loginInfoNotFound',
  loginPhoneRequired: 'ERRORS.loginPhoneRequired',
  loginPasswordRequired: 'ERRORS.loginPasswordRequired',
  loginInvalidPassword: 'ERRORS.loginInvalidPassword',
  loginBlockedUser: 'ERRORS.loginBlockedUser',

  // RECOVERY PASSWORD
  recoveryPasswordOldPhoneRequired: 'ERRORS.required', // Отсутствует обязательный(ые) ключ(и) "old_phone"
  recoveryPasswordNewPhoneRequired: 'ERRORS.required', // Отсутствует обязательный(ые) ключ(и) "new_phone"
  recoveryPasswordInnRequired: 'ERRORS.required', // Отсутствует обязательный(ые) ключ(и) "inn"
  recoveryPasswordInnTooLong: 'ERRORS.valueTooLong', // Значение ключа "inn" превышает максимальную длину
  recoveryPasswordOldPhoneInvalid: 'ERRORS.invalidValue', // Значение ключа "old_phone" невалидно
  recoveryPasswordNewPhoneInvalid: 'ERRORS.invalidValue', // 	Значение ключа "new_phone" невалидно

  // RESET PASSWORD
  passwordResetCodePhoneRequired: 'ERRORS.required', // Отсутствует обязательный(ые) ключ(и) "phone"
  passwordResetCodeInformationNotFound: 'ERRORS.informationNotFound', // Информация не найдена
  passwordResetCodeTooManyCodes: 'ERRORS.tooManyCode', // too_many_codes
  passwordResetCodeInterval: 'ERRORS.tooManyCode', // too_small_interval_between_code

  // PASSWORD RESET
  passwordResetIsUsed: '', // is_used
  passwordResetPasswordRequired: 'ERRORS.required',
  passwordResetCodeRequired: 'ERRORS.required',
  passwordResetInformationNotFound: 'ERRORS.informationNotFound',
  passwordResetInvalidCode: 'ERRORS.invalidCode',

  // REGISTRATION
  registrationPasswordRequired: 'ERRORS.required',
  registrationPhoneRequired: 'ERRORS.required',
  registrationPhoneInvalid: 'ERRORS.phoneInvalid',
  registrationPhoneIsUsed: 'ERRORS.phoneIsUsed',
  registrationPasswordInvalidFormat: 'ERRORS.passwordInvalidFormat',

  // REPEAT REG CODE
  repeatRegistrationCodeInfoNotFound: 'Інформацію не знайдено', // Информация не найдена
  repeatRegistrationCodeTooManyCode: 'Код запитується занадто часто', // Код запрашивается слишком часто
  repeatRegistrationCodeTooManyCodeRequests: 'Забагато кодів запрошено за останній час', // Слишком много кодов запрошено за последнее время

  // ADD RECOMMENDERS
  recommendersAddFirstNameRequired: 'Поле обовʼязкове', // Отсутствует обязательный(ые) ключ(и) "first_name"
  recommendersAddFirstNameTooLong: 'Значення за велике', // Значение ключа "first_name" превышает максимальную длину
  recommendersAddSurnameRequired: 'Поле обовʼязкове', // Отсутствует обязательный(ые) ключ(и) "surname"
  recommendersAddSurnameTooLong: 'Значення за довге', // Отсутствует обязательный(ые) ключ(и) "surname"
  recommendersAddRelationshipRequired: 'Поле обовʼязкове', // Отсутствует обязательный(ые) ключ(и) "relationship_id"
  recommendersAddRelationshipInvalid: 'Значення невалідне', // Значение ключа(ей) "relationship_id" слишком мало
  recommendersAddRelationshipInfoNotFound: 'Інформацію не знайдено', // Значение ключа(ей) "relationship_id" слишком мало

  // UPDATE RECOMMENDERS
  recommendersUpdateInfoNotFound: 'Інформацію не знайдено', // Информация не найдена
  recommendersUpdateFirstNameTooLong: 'Значення завелике', // Значение ключа "first_name" превышает максимальную длину
  recommendersUpdateSurnameTooLong: 'Значення за велике', // Отсутствует обязательный(ые) ключ(и) "surname"
  recommendersUpdateRelationshipInvalid: 'Значення не валідне', // Значение ключа(ей) "relationship_id" слишком велико
  recommendersUpdateRelationshipInfoNotFound: 'Інформацію не знайдемо', // Значение ключа(ей) "relationship_id" слишком мало

  // RECOMMENDERS PHONE ADD
  recommendersPhoneAddPhoneRequired: 'Поле обовʼязкове', // Отсутствует обязательный(ые) ключ(и) "phone"
  recommendersPhoneAddInvalidFormat: 'Значення не валідне', // Значение ключа(ей) "phone" имеет неверный формат

  // RECOMMENDERS PHONE UPDATE
  recommendersPhoneUpdateInfoNotFound: 'Інформацію не знайдено', // Информация не найдена
  recommendersPhoneUpdateInvalidFormat: '', // Значение ключа(ей) "phone" имеет неверный формат

  // AUTH VERIFY
  authVerifyInformationNotFound: 'ERRORS.informationNotFound', // Информация не найдена
  authVerifyInvalidCode: 'ERRORS.invalidCode', // Значение ключа(ей) "code" невалидно
  authVerifyBlockedUser: 'ERRORS.loginBlockedUser', // Возможность входа для пользователя заблокирована

  // PAYMENT INSTANCE
  paymentInstanceNumberRequired: 'ERRORS.required',
  paymentInstanceNumberTooLong: 'ERRORS.valueTooLong',
  paymentInstanceNumberVisaMastercard: 'ERRORS.cardVisaOrMastercard', // Кредитная карта должна быть Visa или Mastercard
  paymentInstanceNumberUniq: 'ERRORS.uniq', // Значение ключа "number" должно быть уникальным
  invalidCreditCardNumber: 'ERRORS.invalidCreditCardNumber',
  invalidCreditCardType: 'ERRORS.invalidCreditCardType',
  // CP CALCULATE
  cpCalculateCreditProductIdRequired: 'ERRORS.required', // Отсутствует обязательный(ые) ключ(и) "credit_product_id"
  cpCalculateAmountRequired: 'ERRORS.required', // Отсутствует обязательный(ые) ключ(и) "amount"
  cpCalculateAmountTooBig: 'ERRORS.valueTooBig', // Значение ключа(ей) "amount" слишком велико
  cpCalculateAmountToSmall: 'ERRORS.valueTooSmall', // Значение ключа(ей) "amount" слишком мало
  cpCalculateIntervalRequired: 'ERRORS.required', // Отсутствует обязательный(ые) ключ(и) "interval"
  cpCalculateIntervalTooBig: 'ERRORS.valueTooBig', // Значение ключа(ей) "interval" слишком велико
  cpCalculateIntervalTooSmall: 'ERRORS.valueTooSmall', // Значение ключа(ей) "interval" слишком мало
  cpCalculatePromoCodeNotFound: 'ERRORS.promoCodeNotFound', // 	Связанные данные "promo_code" не найдены
  // APPLICATION CREATE
  applicationCreateCantCreate: 'ERRORS.cantCreateApplication', // Заявка не может быть подана
  applicationCreateStartAmountRequired: 'ERRORS.required', //	Отсутствует обязательный(ые) ключ(и) "start_amount"
  applicationCreateStartAmountTooBig: 'ERRORS.valueTooBig', // Значение ключа(ей) "start_amount" слишком велико
  applicationCreateStartAmountTooSmall: 'ERRORS.valueTooSmall', // Значение ключа(ей) "start_amount" слишком мало
  applicationCreateStartIntervalRequired: 'ERRORS.required', // Отсутствует обязательный(ые) ключ(и) "start_interval"
  applicationCreateStartIntervalTooBig: 'ERRORS.valueTooBig', // Значение ключа(ей) "start_interval" слишком велико
  applicationCreateStartIntervalTooSmall: 'ERRORS.valueTooSmall', // Значение ключа(ей) "start_interval" слишком мало
  applicationCreateStartCreditProductIdNotFound: 'ERRORS.creditProductNotFound', // Связанные данные "start_credit_product_id" не найдены
  applicationCreateUserCreditCardIdNotFound: 'ERRORS.creditCardNotFound', // Связанные данные "user_credit_card_id" не найдены
  applicationCreateUserCreditCardNotVerified: 'ERRORS.creditCardNotVerified', // Кредитная карта не верифицирована
  applicationCreateUserPhoneIdNotFound: 'ERRORS.userPhoneNotVerified', // Связанные данные "user_phone_id" не найдены
  applicationCreateUserPhoneIdNotVerified: 'ERRORS.userPhoneNotVerified', // Номер телефона не верифицирован
  applicationCreatePromoCodeCantSave: 'ERRORS.promoCodeInvalid', // Ошибка при сохранении "promo_code"
  applicationCreatePromoCodeInactive: 'ERRORS.promoCodeInactive', // Значение ключа "promo_code" не активен
  applicationCreatePromoCodeAlreadyUsed: 'ERRORS.promoCodeAlreadyUsed', // Значение ключа "promo_code" уже использовался пользователем
  // COMPLETE REGISTRATION
  loginWebSocketNotAvailable: 'Вебсокет не доступний',
  // SIGN CREDIT AGREEMENT BY SMS
  signCreditAgreementBySmsInfoNotFound: 'Інформація не знайдена', // Информация не найдена
  signCreditAgreementBySmsCodeRequired: 'Поле обовʼязкове', // Отсутствует обязательный(ые) ключ(и) "code"
  signCreditAgreementBySmsCodeInvalid: 'Код не валідний', // Значение ключа(ей) "code" невалидно
  signCreditAgreementBySmsInvalidApplicationStatus: 'Поточний статус кредиту не відповідає необхідному', // Текущий статус кредита не соответствует требуемому

  // PERSONAL DATA FORM DETAILS ERRORS
  'invalid sex_id according to inn parse': 'Невірна стать згідно з аналізом ІПН',
  'invalid birth_date according to inn parse': 'Невірна дата народження відповідно до аналізу ІПН',
  'inn is not unique': 'Користувач з таким ІПН вже зареєстрований',
  'invalid "record_number"': 'Значення не валідне',
  'invalid sex according to record_number parse': 'Невірна стать відповідно до аналізу Номера запису в паспорті',
  'invalid birth_date according to record_number parse':
    'Невірна дата народження відповідно до аналізу Номера запису в паспорті',

  // ADDRESS DISTRICT
  districtAddOriginalNameRequired: 'Поле обовʼязкове', // Отсутствует обязательный(ые) ключ(и) "original_name",
  districtAddOriginalNameTooLong: 'Значення завелике', // Значение ключа "original_name" превышает максимальную длину,
  districtAddInfoNotFound: 'Інформацію не знайдено', // Связанные данные "address_region_id" не найдены,

  // ADDRESS CITY
  cityAddInfoNotFound: 'Інформацію не знайдено', // Связанные данные "address_district_id" не найдены
  cityAddOriginalNameRequired: 'Поле обовʼязкове', // Отсутствует обязательный(ые) ключ(и) "original_name"
  cityAddOriginalNameTooLong: 'Значення завелике', // Значение ключа "original_name" превышает максимальную длину

  // ADDRESS STREET
  streetAddOriginalNameRequired: 'Поле обовʼязкове', // Отсутствует обязательный(ые) ключ(и) "original_name"
  streetAddOriginalNameTooLong: 'Значення завелике', // Значение ключа "original_name" превышает максимальную длину
  streetAddInfoNotFound: 'Інформацію не знайдено', // 	Связанные данные "address_city_id" не найдены

  // ADDRESS HOUSE
  houseAddNameRequired: 'Поле обовʼязкове', // Отсутствует обязательный(ые) ключ(и) "name"
  houseAddNameTooLong: 'Значення завелике', // Значение ключа "name" превышает максимальную длину
  houseAddInfoNotFound: 'Інформацію не знайдено', // Связанные данные "address_street_id" не найдены

  // ADDRESS FLAT
  flatAddNameRequired: 'Поле обовʼязкове', // Отсутствует обязательный(ые) ключ(и) "name"
  flatAddNameTooLong: 'Значення завелике', // Значение ключа "name" превышает максимальную длину
  flatAddInfoNotFound: 'Інформацію не знайдено', // Связанные данные "address_house_id" не найдены

  invalidSerial: 'Значення не валідне',
  valueMatch: 'ERRORS.valueMatch',

  // SIGN CREDIT PASSPORT
  signCreditPassportCodeRequired: 'Поле обовʼязкове',
  signCreditPassportCodeInvalid: 'Значення не валідне',
  signCreditPassportInfoNotFound: 'Інформацію не знайдено',
  signCreditPassportAlreadySigned: 'Документ вже підписано',

  // NOTIFY FOR SIGN CREDIT PASSPORT
  notifyForSignCreditPassportInfoNotFound: 'Інформацію не знайдено',
  notifyForSignCreditPassportApplicationStatusInvalid: 'Інформацію не знайдено',
  notifyForSignCreditPassportAlreadySigned: 'Документ вже підписано',

  //
  uploadSignedFileAuthKeyInvalid: 'Файл не валідний',
  uploadSignedFileSignatureInvalid: 'Файл має бути підписаний',
  uploadSignedFileInnInvalid: 'Файл не валідний',
  uploadSignedFileMimTypesInvalid: 'Не валідний тип файлу',
  uploadSignedFileUserFileNotFound: 'Дані не знайдено',

  creditCreateEmptyContactId: '',
  creditCreateContactNotFound: 'Контакт не знайдений',
  createCreditInvalidContact: 'Контакт не валдіний',

  requestTransferTooManyRequestForLastTime: 'Занадто багато кодів запрошено останнім часом',
  requestTransferTooManyRequest: 'Код запитується занадто часто',

  //USER-INTEGRATION-BANK-ID-NBU
  userIntegrationBankIdNbuLimitExpired: 'ERRORS.limitNumberOfSamplesExhausted'
};
