import { ControlSettingsNameEnum } from '@core/enums';

export const PassportControlsStep = [
  ControlSettingsNameEnum.Surname,
  ControlSettingsNameEnum.FirstName,
  ControlSettingsNameEnum.MiddleName,
  ControlSettingsNameEnum.PassportRecordId,
  ControlSettingsNameEnum.PassportSeria,
  ControlSettingsNameEnum.PassportNumberId,
  ControlSettingsNameEnum.PassportIssuedBy,
  ControlSettingsNameEnum.PassportIssueDate,
  ControlSettingsNameEnum.PassportExpireDate,
  ControlSettingsNameEnum.PassportTypeId,
];

export const PassportBookControls = [
  ControlSettingsNameEnum.PassportTypeId,
  ControlSettingsNameEnum.PassportNumberId,
  ControlSettingsNameEnum.PassportSeria,
  ControlSettingsNameEnum.PassportIssuedBy,
  ControlSettingsNameEnum.PassportIssueDate,
];

export const PassportIdControls = [
  ControlSettingsNameEnum.PassportTypeId,
  ControlSettingsNameEnum.PassportNumberId,
  ControlSettingsNameEnum.PassportIssueDate,
  ControlSettingsNameEnum.PassportExpireDate,
  ControlSettingsNameEnum.PassportRecordId,
  ControlSettingsNameEnum.PassportIssuedBy,
];

export const AddressControlsStep = [
  ControlSettingsNameEnum.RegistrationAddressRegionId,
  ControlSettingsNameEnum.RegistrationAddressDistrictId,
  ControlSettingsNameEnum.RegistrationAddressCityId,
  ControlSettingsNameEnum.RegistrationAddressStreetId,
  ControlSettingsNameEnum.RegistrationAddressHouseId,
  ControlSettingsNameEnum.RegistrationAddressZipCode,
  ControlSettingsNameEnum.RegistrationAddressFlatId,
  ControlSettingsNameEnum.IsActualAddressSameRegistration,
  ControlSettingsNameEnum.ActualAddressRegionId,
  ControlSettingsNameEnum.ActualAddressDistrictId,
  ControlSettingsNameEnum.ActualAddressCityId,
  ControlSettingsNameEnum.ActualAddressStreetId,
  ControlSettingsNameEnum.ActualAddressHouseId,
  ControlSettingsNameEnum.ActualAddressZipCode,
  ControlSettingsNameEnum.ActualAddressFlatId,
];

export const AddressActualControls = [
  ControlSettingsNameEnum.ActualAddressRegionId,
  ControlSettingsNameEnum.ActualAddressDistrictId,
  ControlSettingsNameEnum.ActualAddressCityId,
  ControlSettingsNameEnum.ActualAddressStreetId,
  ControlSettingsNameEnum.ActualAddressHouseId,
  ControlSettingsNameEnum.ActualAddressZipCode,
  ControlSettingsNameEnum.ActualAddressFlatId,
];

export const AddressRegistrationControls = [
  ControlSettingsNameEnum.RegistrationAddressRegionId,
  ControlSettingsNameEnum.RegistrationAddressDistrictId,
  ControlSettingsNameEnum.RegistrationAddressCityId,
  ControlSettingsNameEnum.RegistrationAddressStreetId,
  ControlSettingsNameEnum.RegistrationAddressHouseId,
  ControlSettingsNameEnum.RegistrationAddressZipCode,
  ControlSettingsNameEnum.RegistrationAddressFlatId,
];

export const EmploymentsControls = [
  // ControlSettingsNameEnum.SocialStatusId,
  ControlSettingsNameEnum.WorkCompanyTypeId,
  ControlSettingsNameEnum.WorkCompanyName,
  ControlSettingsNameEnum.WorkPostId,
  ControlSettingsNameEnum.IncomeAmount,
];

export const SecretQuestionControls = [ControlSettingsNameEnum.SecretQuestionId, ControlSettingsNameEnum.SecretAnswer];

export const UnemployedControls = [
  //  ControlSettingsNameEnum.SocialStatusId,
  ControlSettingsNameEnum.IncomeAmount,
];

export const WorkingControls = [
  // ControlSettingsNameEnum.SocialStatusId,
  ControlSettingsNameEnum.WorkCompanyTypeId,
  ControlSettingsNameEnum.WorkCompanyName,
  ControlSettingsNameEnum.WorkPostId,
  ControlSettingsNameEnum.IncomeAmount,
];
