import { Injectable } from '@angular/core';
import { STORAGE_CONSTANT } from '@core/constants';
import { ICompleteRegistration, IUser } from '@core/interfaces';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly storageSvc: StorageService) {}

  token(): string | null {
    return this.storageSvc.get<string>(STORAGE_CONSTANT.TOKEN);
  }

  saveAuthUserData(data?: ICompleteRegistration): void {
    this.storageSvc.set<string>(STORAGE_CONSTANT.TOKEN, data?.token ?? '');
    this.storageSvc.set<string | number | null>(STORAGE_CONSTANT.USER_ID, data?.user?.id ?? null);
    this.storageSvc.set<IUser>(STORAGE_CONSTANT.USER, data?.user as IUser);
  }

  saveToken(token: string): void {
    this.storageSvc.set<string>(STORAGE_CONSTANT.TOKEN, token ?? '');
  }

  removeToken(): void {
    this.storageSvc.delete(STORAGE_CONSTANT.TOKEN);
  }

  userStoredData() {
    return this.storageSvc.get<IUser>(STORAGE_CONSTANT.USER);
  }
}
