import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { STORAGE_CONSTANT } from '@core/constants';
import { ISettings, IUser } from '@core/interfaces';
import { AuthService, SettingsService, StorageService } from '@services';
import { map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-admin-auth',
  templateUrl: './admin-auth.component.html',
  styleUrls: ['./admin-auth.component.scss'],
  standalone: true,
})
export class AdminAuthComponent {
  constructor(
    private readonly router: Router,
    private readonly activeRout: ActivatedRoute,
    private readonly storageSvc: StorageService,
    private readonly authSvc: AuthService,
    private readonly settingsSvc: SettingsService,
  ) {
    this.activeRout.queryParams
      .pipe(
        tap(({ token }) => {
          if (!token) this.router.navigate(['/']);
          if (token) this.storageSvc.set<string>(STORAGE_CONSTANT.TOKEN, token);
        }),
        switchMap(() => this.settingsSvc.getSettingsList().pipe(map((r) => r?.data))),
        tap((data) => this.storageSvc.set<ISettings>(STORAGE_CONSTANT.SETTINGS, data)),
        switchMap(() => this.authSvc.getUser()),
        map((r) => r?.data),
        tap((user) => {
          this.storageSvc.set<string | number>(STORAGE_CONSTANT.USER_ID, user?.id);
          this.storageSvc.set<IUser>(STORAGE_CONSTANT.USER, user);
          this.router.navigate(['/'], { replaceUrl: true });
        }),
      )
      .subscribe();
  }
}
