import { Injectable } from '@angular/core';
import { ITrafficData } from '@core/interfaces';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class TrafficService {
  constructor(private readonly cookieService: CookieService) {}

  get traffic(): ITrafficData {
    return {
      utm_source: this.cookieService.get('utm_source'),
      utm_medium: this.cookieService.get('utm_medium'),
      utm_campaign: this.cookieService.get('utm_campaign'),
      utm_term: this.cookieService.get('utm_term'),
      utm_content: this.cookieService.get('utm_content'),
      click_id: this.cookieService.get('visitor') ? this.cookieService.get('visitor') : this.cookieService.get('_ga'),
      webmaster: this.cookieService.get('affiliate'),
      sub_1: this.cookieService.get('sub_1'),
      sub_2: this.cookieService.get('sub_2'),
    };
  }
}
