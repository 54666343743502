import { Injectable } from '@angular/core';
import { IAddressItem, IAddressOption, IdData, IResponseData } from '@core/interfaces';
import { serializeAddressItemData } from '@core/utils';
import { map, Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private readonly apiSvc: ApiService) {
  }

  addressRegion(body = {}): Observable<IAddressItem[]> {
    return this.apiSvc.post<IResponseData<Array<IAddressItem>>>('user/address-region/list', body).pipe(
      map((r) => r?.data ?? []),
      map((data) => (data ?? []).map((item) => serializeAddressItemData(item))),
    );
  }

  addressDistrict(body = {}): Observable<Array<IAddressItem>> {
    return this.apiSvc.post<IResponseData<Array<IAddressOption>>>('user/address-district/list', body).pipe(
      map((r) => r?.data ?? []),
      map((data) => (data ?? []).map((item) => serializeAddressItemData(item))),
    );
  }

  addressCity(body = {}): Observable<Array<IAddressItem>> {
    return this.apiSvc.post<IResponseData<Array<IAddressOption>>>('user/address-city/list', body).pipe(
      map((r) => r?.data ?? []),
      map((data) => (data ?? []).map((item) => serializeAddressItemData(item))),
    );
  }

  addressStreet(body = {}): Observable<Array<IAddressItem>> {
    return this.apiSvc.post<IResponseData<Array<IAddressOption>>>('user/address-street/list', body).pipe(
      map((r) => r?.data ?? []),
      map((data) => (data ?? []).map((item) => serializeAddressItemData(item))),
    );
  }

  addressHouse(body = {}): Observable<Array<IAddressItem>> {
    return this.apiSvc.post<IResponseData<Array<IAddressOption>>>('user/address-house/list', body).pipe(
      map((r) => r?.data ?? []),
      map((data) => (data ?? []).map((item) => serializeAddressItemData(item))),
    );
  }

  addressFlat(body = {}): Observable<Array<IAddressItem>> {
    return this.apiSvc.post<IResponseData<Array<IAddressOption>>>('user/address-flat/list', body).pipe(
      map((r) => r?.data ?? []),
      map((data) => data?.map((item) => serializeAddressItemData(item)),
      ),
    );
  }

  addCity(body = {}): Observable<number> {
    return this.apiSvc.post<IResponseData<IdData>>('user/address-city/add', body).pipe(map((r) => r?.data.id));
  }

  addStreet(body = {}): Observable<number> {
    return this.apiSvc.post<IResponseData<IdData>>('user/address-street/add', body).pipe(map((r) => r?.data.id));
  }

  addHouse(body = {}): Observable<number> {
    return this.apiSvc.post<IResponseData<IdData>>('user/address-house/add', body).pipe(map((r) => r?.data.id));
  }

  addFlat(body = {}): Observable<number> {
    return this.apiSvc.post<IResponseData<IdData>>('user/address-flat/add', body).pipe(map((r) => r?.data.id));
  }
}
