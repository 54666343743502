import { Injectable } from '@angular/core';
import { IdData, IPaymentInstance, IResponseData, IVerifyPaymentInstance } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentInstanceService {
  constructor(private readonly apiSvc: ApiService) {}

  list(body = {}): Observable<IResponseData<IPaymentInstance[]>> {
    return this.apiSvc.post('user/user-payment-instance/list', body);
  }

  add(body = {}): Observable<IResponseData<IdData>> {
    return this.apiSvc.post('user/user-payment-instance/add', body);
  }

  verify(body = {}): Observable<IResponseData<IVerifyPaymentInstance>> {
    return this.apiSvc.post('user/user-payment-instance/verify', body);
  }

  setMainCard(id: number): Observable<IResponseData<void>> {
    return this.apiSvc.post('user/user-payment-instance/set-main', { id });
  }

  changePaymentInstance(body = {}): Observable<unknown> {
    return this.apiSvc.post('user/application/change-payment-instance', body);
  }

  delete(id: number | string): Observable<IResponseData<unknown>> {
    return this.apiSvc.post('user/user-payment-instance/delete', { id });
  }
}
