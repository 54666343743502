import { Injectable } from '@angular/core';
import { UserAutoTagTypeEnum } from '@core/enums';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private readonly apiSvc: ApiService) {}

  autoAssignTag(type: UserAutoTagTypeEnum): Observable<void> {
    return this.apiSvc.post('user/user/auto-assign-tag', { type });
  }
}
