import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { STORAGE_CONSTANT } from '@core/constants';
import { ENVIRONMENT } from '@core/environment';
import { IEnvironment, IErrorResponseItem } from '@core/interfaces';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { ProfileDataStore } from '@core/state';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly userSvc: UserService,
    private readonly router: Router,
    private readonly storageSvc: StorageService,
    private readonly store: ProfileDataStore,
    @Inject(ENVIRONMENT) private env: IEnvironment,
  ) {
  }

  post<T>(path: string, body?: unknown, responseType = 'json'): Observable<T> {
    return this.httpClient
      .post<T>(`${this.env.apiUrl}${path}`, body ?? {}, {
        headers: new HttpHeaders()
          .append('Content-Type', 'application/json')
          .append('Authorization', 'Bearer ' + this.userSvc.token())
          .append('BRAND-ID', this.env.brandId)
          .append('DATA-SOURCE', '2'),
        withCredentials: true,
        responseType: responseType as 'json',
      })
      .pipe(
        catchError((e) => {
          // this.tokenHandler(e?.error?.data ?? []);
          return throwError(() => e?.error?.data ?? []);
        }),
      );
  }

  postFile<T>(path: string, body?: unknown): Observable<T> {
    return this.httpClient
      .post<T>(`${this.env.apiUrl}${path}`, body ?? {}, {
        headers: new HttpHeaders()
          .append('Authorization', 'Bearer ' + this.userSvc.token())
          .append('BRAND-ID', this.env.brandId)
          .append('DATA-SOURCE', '2'),
        withCredentials: true,
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((e) => {
          // this.tokenHandler(e?.error?.data ?? []);
          return throwError(() => e?.error?.data ?? []);
        }),
      );
  }

  get<T>(path: string): Observable<T> {
    return this.httpClient
      .get<T>(`${this.env.apiUrl}${path}`, {
        headers: new HttpHeaders()
          .append('Content-Type', 'application/json')
          .append('Authorization', 'Bearer ' + this.userSvc.token())
          .append('BRAND-ID', this.env.brandId)
          .append('DATA-SOURCE', '2'),
        withCredentials: true,
      })
      .pipe(
        switchMap((d) => {
          // Server error response return as array with status code 200;
          const isErrors = d instanceof Array;
          // if (isErrors) this.tokenHandler(d);
          return isErrors ? throwError(() => d) : of(d);
        }),
      );
  }

  upload<T>(path: string, body?: Record<string, unknown>): Observable<T> {
    return this.httpClient
      .post<T>(`${this.env.apiUrl}${path}`, body ?? {}, {
        headers: new HttpHeaders()
          .append('Authorization', 'Bearer ' + this.userSvc.token())
          .append('BRAND-ID', this.env.brandId)
          .append('DATA-SOURCE', '2'),
        withCredentials: true,
      })
      .pipe(
        switchMap((d) => {
          // Server error response return as array with status code 200;
          const isErrors = d instanceof Array;
          return isErrors ? throwError(() => d) : of(d);
        }),
        catchError((e) => {
          const err = e?.error?.message;
          // When user was deleted logout;
          if (err?.includes('SQLSTATE[42000]')) this.logout();
          // this.tokenHandler(e?.error?.data ?? []);
          return throwError(e?.error?.data ?? []);
        }),
      );
  }

  logout(): void {
    this.userSvc.removeToken();
    this.storageSvc.removeKeys([
      STORAGE_CONSTANT.AFTER_LOGIN,
      STORAGE_CONSTANT.REGISTRATION_LOGIN,
      STORAGE_CONSTANT.SETTINGS,
      STORAGE_CONSTANT.WS_DATA,
      STORAGE_CONSTANT.SKIP_BANK_ID_STEP,
      STORAGE_CONSTANT.CALL_STATUS,
      STORAGE_CONSTANT.REFERRAL_LINK,
      STORAGE_CONSTANT.VERIFICATION_TYPE,
      STORAGE_CONSTANT.CAN_USE_CASHBACK,
      STORAGE_CONSTANT.USER_ID,
      STORAGE_CONSTANT.USER,
      STORAGE_CONSTANT.IS_LK_STEP,
    ]);
    this.store.reset();
    this.router.navigate(['/']);
  }

  private tokenHandler(errors: IErrorResponseItem[]): void {
    const accessDenied = errors.find((e) => e.code === -1001);
    if (accessDenied) this.logout();
  }
}
