import { ControlSettingsNameEnum } from '@core/enums';

export const AddressKeyByFiledNameMap = new Map<ControlSettingsNameEnum, string>([
  [ControlSettingsNameEnum.RegistrationAddressRegionId, 'registrationAddressRegion'],
  [ControlSettingsNameEnum.RegistrationAddressCityId, 'registrationAddressCity'],
  [ControlSettingsNameEnum.RegistrationAddressStreetId, 'registrationAddressStreet'],
  [ControlSettingsNameEnum.RegistrationAddressHouseId, 'registrationAddressHouse'],
  [ControlSettingsNameEnum.RegistrationAddressFlatId, 'registrationAddressFlat'],

  [ControlSettingsNameEnum.ActualAddressRegionId, 'actualAddressRegion'],
  [ControlSettingsNameEnum.ActualAddressCityId, 'actualAddressCity'],
  [ControlSettingsNameEnum.ActualAddressStreetId, 'actualAddressStreet'],
  [ControlSettingsNameEnum.ActualAddressHouseId, 'actualAddressHouse'],
  [ControlSettingsNameEnum.ActualAddressFlatId, 'actualAddressFlat'],
]);
