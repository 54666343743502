export enum AddressControlsTypes {
  /** Страна, по умолчанию Украина*/
  Country = 'country',
  /** Область*/
  State = 'state',
  /** Областной район*/
  Zone = 'zone',
  /** Населенный пункт*/
  City = 'city',
  /** Тип жилья (Квартира | Частный дом)*/
  HousingType = 'housing_type',
  /** Индекс*/
  PostIndex = 'post_index',
  /** Улица*/
  Street = 'street',
  /** Дом*/
  House = 'house',
  /** Корпус*/
  Build = 'build',
  /** Квартира*/
  Flat = 'flat',
}
