import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class TrafficInitService {
  protected readonly trafficKeys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'affiliate',
    'sub_1',
    'sub_2',
  ];

  constructor(private readonly cookieSvc: CookieService) {}

  resolve(): Promise<void> {
    return new Promise((resolve) => {
      this.setQueryParamsAppInit();
      return resolve();
    });
  }

  setQueryParamsAppInit() {
    this.setTraffic(this.getQueryParamsData());
  }

  private getQueryParamsData() {
    const fullUrl = location.href;
    const allQueryParamsString = fullUrl?.split('?')?.[1] ?? '';
    const queryParamArray = allQueryParamsString?.split('&');
    return queryParamArray.reduce((acc: Record<string, string>, item) => {
      const [key, value] = item.split('=');
      if (key) acc[key] = value;
      return acc;
    }, {});
  }

  private setTraffic(queryParamData: Record<string, string>) {
    this.trafficKeys.forEach((key) => {
      if (queryParamData[key]) this.cookieHandler(key, queryParamData[key]);
    });
  }

  private cookieHandler(key: string, value: string) {
    const hasValue = this.cookieSvc.check(key);
    if (hasValue) this.cookieSvc.delete(key);
    this.cookieSvc.set(key, value);
  }
}
