import { Injectable } from '@angular/core';
import { IClassificatorItem, IControlSetting, IOptionItem, IResponseData } from '@core/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ClassificatorService {
  constructor(private readonly apiSvc: ApiService, private readonly translateService: TranslateService) {}

  getClassificatorItems(id: number | string): Observable<IResponseData<IClassificatorItem[]>> {
    const body = {
      alias: 'gli',
      filters: {
        function: 'and',
        where: [
          {
            check: '=',
            key: 'classificator_id',
            value: id,
          },
          {
            check: '=',
            key: 'is_active',
            value: true,
          },
        ],
      },
      with: [
        {
          name: 'classificatorItemLocales',
          alias: 'cil',
          join_type: 'LEFT JOIN',
        },
      ],
      expand: ['classificatorItemLocales'],
    };
    return this.apiSvc.post('user/classificator-item/list', body);
  }

  getClassificatorItem(type: number | string) {
    const body = {
      alias: 'gli',
      filters: {
        function: 'and',
        where: [
          {
            check: '=',
            key: 'type',
            value: type,
          },
        ],
      },
    };
    return this.apiSvc.post<IResponseData<IClassificatorItem[]>>('user/classificator/list', body).pipe(
      map((r: IResponseData<IClassificatorItem[]>) => (r?.data && r?.data[0]) || {}),
      switchMap((item: IClassificatorItem) => (item?.id ? this.getClassificatorItems(item.id) : of(null))),
    );
  }

  getControlClassificatorItems(control: IControlSetting) {
    return this.getClassificatorItem(control.classificatorType).pipe(
      map((r) => {
        control.list = this.generateOption(r?.data ?? [])?.sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0));
        return control;
      }),
    );
  }

  private generateOption(options: IClassificatorItem[]): IOptionItem[] {
    const lang = this.translateService.currentLang ?? 'uk';
    return options.map((item: IClassificatorItem) => {
      const locale = item.classificatorItemLocales?.find((cl) => cl.language === lang);
      return {
        value: item.id,
        title: locale?.text || item?.classificatorItemLocales[0]?.text || item?.key,
        key: item.key,
        order: item?.order_id,
      };
    });
  }
}
