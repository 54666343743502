export enum ProfileBlock {
  /** блок, отвечающий за личные данные */
  PersonalData = 'form_block_personal_data',
  /** блок, отвечающий за адрес регистрации и адрес фактического проживания*/
  Address = 'form_block_address',
  /** блок, отвечающий за занятость */
  Work = 'form_block_work',
  /** блок, отвечающий за файлы заемщик */
  Files = 'form_block_files',
  /** блок, отвечающий за рекомендательные лица заемщика */
  Recommenders = 'form_block_recommender',
  /** блок, отвечающий за комментарий от заемщика */
  Comment = 'form_block_comment',
  /** блок, отвечающий за контрольный вопрос */
  SecretQuestion = 'form_block_secret_question',
}
