export const ERROR_RESPONSE_CODE: { [key: string]: string } = {
  // LOGIN
  // '-2001005001': 'loginInfoNotFound', // Информация не найдена;
  // '-2001005002': 'loginPhoneRequired', // Отсутствует обязательный(ые) ключ(и) "phone"
  // '-2001005003': 'loginPasswordRequired', // Отсутствует обязательный(ые) ключ(и) "password"
  // '-2001005004': 'loginInfoNotFound', // Информация не найдена
  // '-2001005005': 'loginInvalidPassword', // Не правильный пароль
  // '-2001005007': 'loginSourceRequired', // Отсутствует обязательный(ые) ключ(и) "source"
  // '-2001005009': 'loginLocationLatitudeIsRequired', // Отсутствует обязательный(ые) ключ(и) "location_latitude_is"
  // '-2001005010': 'loginLocationLatitudeInvalidValue', // Неправильное значение ключа "location_latitude"
  // '-2001005029': 'loginBlockedUser', // Возможность входа для пользователя заблокирована
  // '-2001005020': 'loginCodeToManyRequests', //
  // '-2001005021': 'loginCodeRequestedToOften', //
  // '-2001005030': 'loginWebSocketNotAvailable', // Вебсокет недоступен

  // UBKI EXPORT
  '-2031001001': 'ubkiImportFileNotFound',
  '-2031001002': 'ubkiImportFileInvalid',
  '-2031001003': 'ubkiImportInvalidReport',
  '-2031001004': 'ubkiImportInvalidInn',

  // RECOVERY PASSWORD
  '-2012001001': 'recoveryPasswordOldPhoneRequired', // Отсутствует обязательный(ые) ключ(и) "old_phone"
  '-2012001003': 'recoveryPasswordNewPhoneRequired', // Отсутствует обязательный(ые) ключ(и) "new_phone"
  '-2012001005': 'recoveryPasswordInnRequired', // Отсутствует обязательный(ые) ключ(и) "inn"
  '-2012001007': 'recoveryPasswordInnTooLong', // Значение ключа "inn" превышает максимальную длину
  '-2012001009': 'recoveryPasswordOldPhoneInvalid', // Значение ключа "old_phone" невалидно
  '-2012001010': 'recoveryPasswordNewPhoneInvalid', // 	Значение ключа "new_phone" невалидно

  // PASSWORD RESET CODE
  '-2001010001': 'passwordResetCodePhoneRequired', // Отсутствует обязательный(ые) ключ(и) "phone"
  '-2001010002': 'passwordResetCodeInformationNotFound', // Информация не найдена
  '-2001010003': 'passwordResetCodeTooManyCodes', // too_many_codes
  '-2001010004': 'passwordResetCodeInterval', // too_small_interval_between_code

  // PASSWORD RESET
  '-2001011003': 'passwordResetIsUsed', // is_used
  '-2001011004': 'passwordResetPasswordRequired',
  '-2001011011': 'passwordResetCodeRequired',
  '-2001011012': 'passwordResetInformationNotFound',
  '-2001011013': 'passwordResetInvalidCode',

  // REGISTRATION
  '-2001008002': 'userRegistrationPasswordRequired',
  '-2001008004': 'userRegistrationPhoneRequired',
  '-2001008005': 'userRegistrationPhoneInvalid',
  '-2001008010': 'userRegistrationPhoneIsUsed',
  '-2001008011': 'userRegistrationPasswordTooShort',
  '-2001008012': 'userRegistrationPasswordTooLong',
  '-2001008013': 'userRegistrationPasswordNeedNumber',
  '-2001008014': 'userRegistrationPasswordBothCase',
  '-2001008015': 'userRegistrationPasswordInvalidFormat',
  '-2001008009': 'userRegistrationFieldsInvalid',
  // REPEAT REG CODE
  '-2001023002': 'repeatRegistrationCodeInfoNotFound', // Информация не найдена
  '-2001023003': 'repeatRegistrationCodeTooManyCode', // Код запрашивается слишком часто
  '-2001023004': 'repeatRegistrationCodeTooManyCodeRequests', // Слишком много кодов запрошено за последнее время

  // REPEAT AUTH CODE
  '-2001006002': 'repeatAuthCodeInfoNotFound', // Информация не найдена
  '-2001006003': 'repeatAuthCodeTooManyRequest', // Код авторизации запрашивается слишком часто
  '-2001006004': 'repeatAuthCodeTooManyRequestForLastTime', // Слишком много кодов запрошено за последнее время
  '-2001006008': 'repeatAuthCodeUserBlocked', // Возможность входа для пользователя заблокирована
  '-2001006006': 'repeatAuthCodePasswordRequired', // Отсутствует обязательный(ые) ключ(и) "password"
  '-2001006007': 'repeatAuthCodePasswordInvalid', // Значение ключа(ей) "password" невалидно
  '-2001006001': 'repeatAuthCodePhoneRequired', // Значение ключа(ей) "password" невалидно

  // AUTH VERIFY
  '-2001007009': 'verifyAuthCodeInfoNotFound', // Інформацію не знайдено
  '-2001007010': 'verifyAuthCodeInvalidCode', // Код не валідний
  '-2001007012': 'verifyAuthCodeTwoFactorDisabled', // Двухфакторная авторизация выключена
  '-2001007013': 'verifyAuthCodeBlockedUser', // Можливість ввійти для користувача заблокована

  // PAYMENT INSTANCE
  '-2004001003': 'paymentInstanceNumberRequired',
  '-2004001005': 'paymentInstanceNumberTooLong',
  '-2004001011': 'paymentInstanceNumberVisaMastercard', // Кредитная карта должна быть Visa или Mastercard
  '-2004001016': 'paymentInstanceNumberUniq', // Значение ключа "number" должно быть уникальным

  // CP CALCULATE
  '-2027002001': 'cpCalculateCreditProductIdRequired', // Отсутствует обязательный(ые) ключ(и) "credit_product_id"
  '-2027002005': 'cpCalculateAmountRequired', // Отсутствует обязательный(ые) ключ(и) "amount"
  '-2027002007': 'cpCalculateAmountTooBig', // Значение ключа(ей) "amount" слишком велико
  '-2027002008': 'cpCalculateAmountToSmall', // Значение ключа(ей) "amount" слишком мало
  '-2027002009': 'cpCalculateIntervalRequired', // Отсутствует обязательный(ые) ключ(и) "interval"
  '-2027002011': 'cpCalculateIntervalTooBig', // 	Значение ключа(ей) "interval" слишком велико
  '-2027002012': 'cpCalculateIntervalTooSmall', // 	Значение ключа(ей) "interval" слишком мало
  '-2027002015': 'cpCalculatePromoCodeNotFound', // 	Значение ключа(ей) "interval" слишком мало
  '-2027002016': 'cpCalculatePromoCodeNotActive', // 	Значение ключа(ей) "promo_code" не активний
  '-2027002017': 'cpCalculatePromoCodeUsed', // 	Значение ключа(ей) "promo_code" уже используется
  '-2027002018': 'cpCalculatePromoCodeWrongCreditProduct', // 	Значение ключа(ей) "promo_code" не валидный для кп
  '-2027002019': 'cpCalculatePromoCodeNotForClient', // 	Значение ключа(ей) "promo_code" не валидный тип клиента
  '-2027002020': 'cpCalculatePromoCodeWrongDate', // 	Значение ключа(ей) "promo_code" не валидный тип клиента

  // CP DETAIL CALCULATE
  '-2027004001': 'cpCalculateCreditProductIdRequired', // Отсутствует обязательный(ые) ключ(и) "credit_product_id"
  '-2027004005': 'cpCalculateAmountRequired', // Отсутствует обязательный(ые) ключ(и) "amount"
  '-2027004007': 'cpCalculateAmountTooBig', // Значение ключа(ей) "amount" слишком велико
  '-2027004008': 'cpCalculateAmountToSmall', // Значение ключа(ей) "amount" слишком мало
  '-2027004009': 'cpCalculateIntervalRequired', // Отсутствует обязательный(ые) ключ(и) "interval"
  '-2027004011': 'cpCalculateIntervalTooBig', // Значение ключа(ей) "interval" слишком велико
  '-2027004012': 'cpCalculateIntervalTooSmall', // Значение ключа(ей) "interval" слишком мало
  '-2027004015': 'cpCalculatePromoCodeNotFound', // Значение ключа(ей) "interval" слишком мало
  '-2027004016': 'cpCalculatePromoCodeNotActive', // Значение ключа(ей) "promo_code" не активний
  '-2027004017': 'cpCalculatePromoCodeUsed', // Значение ключа(ей) "promo_code" уже используется
  '-2027004018': 'cpCalculatePromoCodeWrongCreditProduct', // Значение ключа(ей) "promo_code" не валидный для кп
  '-2027004019': 'cpCalculatePromoCodeNotForClient', // Значение ключа(ей) "promo_code" не валидный тип клиента
  '-2027004020': 'cpCalculatePromoCodeWrongDate', // Значение ключа(ей) "promo_code" не валидный тип клиента

  // APPLICATION CREATE
  '-2006003002': 'applicationCreateCantCreate', // Заявка не может быть подана
  '-2006003003': 'applicationCreateStartAmountRequired', //	Отсутствует обязательный(ые) ключ(и) "start_amount"
  '-2006003004': 'applicationCreateStartAmountTooBig', // Значение ключа(ей) "start_amount" слишком велико
  '-2006003005': 'applicationCreateStartAmountTooSmall', // Значение ключа(ей) "start_amount" слишком мало
  '-2006003007': 'applicationCreateStartIntervalRequired', // Отсутствует обязательный(ые) ключ(и) "start_interval"
  '-2006003008': 'applicationCreateStartIntervalTooBig', // Значение ключа(ей) "start_interval" слишком велико
  '-2006003009': 'applicationCreateStartIntervalTooSmall', // Значение ключа(ей) "start_interval" слишком мало
  '-2006003015': 'applicationCreateStartCreditProductIdNotFound', // Связанные данные "start_credit_product_id" не найдены
  '-2006003020': 'applicationCreateUserCreditCardIdNotFound', // Связанные данные "user_credit_card_id" не найдены
  '-2006003021': 'applicationCreateUserCreditCardNotVerified', // Кредитная карта не верифицирована
  '-2006003026': 'applicationCreateUserPhoneIdNotFound', // Связанные данные "user_phone_id" не найдены
  '-2006003027': 'applicationCreateUserPhoneIdNotVerified', // Номер телефона не верифицирован
  '-2006003035': 'applicationCreatePromoCodeCantSave', // Ошибка при сохранении "promo_code"
  '-2006003037': 'applicationCreatePromoCodeInactive', // Значение ключа "promo_code" не активен
  '-2006003038': 'applicationCreatePromoCodeAlreadyUsed', // Значение ключа "promo_code" уже использовался пользователем

  // VERIFY PHONE
  '-2002007001': 'verifyPhoneInformationNotFound', // Информация не найдена
  '-2002007002': 'verifyPhoneInformationNotFound', // Информация не найдена
  '-2002007003': 'verifyPhoneInformationNotFound', // Информация не найдена
  '-2002007004': 'verifyPhoneVerifyUnknownError', // Возникла не известная ошибка верификации
  '-2002007005': 'verifyPhoneVerifyError', // Ошибка верификации телефона

  // ADD PHONE
  '-2002001003': 'addPhoneInvalidFormat', // Значение ключа(ей) "phone" имеет неверный формат
  '-2002001004': 'addPhoneUniq',
  '-2002001005': 'addPhoneUniq', // Значение ключа(ей) "phone" должно быть уникальным

  // REPEAT VERIFY CODE
  '-2002008002': 'repeatVerifyCodeInfoNotFound', // Информация не найдена
  '-2002008003': 'repeatVerifyCodePhoneAlreadyVerified', // Номер телефона уже верифицирован
  '-2002008004': 'repeatVerifyCodeRequestTooOften', // 	Код запрашивается слишком часто
  '-2002008005': 'repeatVerifyCodeTooManyCodesRecently', // Слишком много кодов запрошено за последнее время

  // COMPLETE REGISTRATION
  '-2001009001': 'completeRegistrationCodeRequired', // Отсутствует обязательный(ые) ключ(и) "code"
  '-2001009023': 'completeRegistrationInfoNotFound', // Информация не найдена
  '-2001009024': 'completeRegistrationInfoNotFound', // Информация не найдена
  '-2001009025': 'completeRegistrationVerifyError', // verified_save_error
  '-2001009026': 'completeRegistrationVerifySaveError', // verified_save_unknown_error
  '-2001009027': 'completeRegistrationRegCompleteError', // registration_completed

  // SIGN CREDIT AGREEMENT BY SMS
  '-2006008005': 'signCreditAgreementBySmsInfoNotFound', // Информация не найдена
  '-2006008006': 'signCreditAgreementBySmsCodeRequired', // Отсутствует обязательный(ые) ключ(и) "code"
  '-2006008008': 'signCreditAgreementBySmsCodeInvalid', // Значение ключа(ей) "code" невалидно
  '-2006008009': 'signCreditAgreementBySmsInvalidApplicationStatus', // Текущий статус кредита не соответствует требуемому

  // NOTIFY FOR SIGN CREDIT AGREEMENT
  '-2006007005': 'notifyForSignCreditAgreementInfoNotFound', //
  '-2006007006': 'notifyForSignCreditAgreementInfoNotFound', //

  // ADD RECOMMENDERS
  '-2011001001': 'recommendersAddFirstNameRequired', // Отсутствует обязательный(ые) ключ(и) "first_name"
  '-2011001003': 'recommendersAddFirstNameTooLong', // Значение ключа "first_name" превышает максимальную длину
  '-2011001004': 'recommendersAddSurnameRequired', // Отсутствует обязательный(ые) ключ(и) "surname"
  '-2011001006': 'recommendersAddSurnameTooLong', // Отсутствует обязательный(ые) ключ(и) "surname"
  '-2011001007': 'recommendersAddRelationshipRequired', // Отсутствует обязательный(ые) ключ(и) "relationship_id"
  '-2011001009': 'recommendersAddRelationshipInvalid', // Значение ключа(ей) "relationship_id" слишком велико
  '-2011001010': 'recommendersAddRelationshipInvalid', // Значение ключа(ей) "relationship_id" слишком мало
  '-2011001011': 'recommendersAddRelationshipInfoNotFound', // Значение ключа(ей) "relationship_id" слишком мало

  // UPDATE RECOMMENDERS
  '-2011002005': 'recommendersUpdateInfoNotFound', // Информация не найдена
  '-2011002007': 'recommendersUpdateFirstNameTooLong', // Значение ключа "first_name" превышает максимальную длину
  '-2011002009': 'recommendersUpdateSurnameTooLong', // Отсутствует обязательный(ые) ключ(и) "surname"
  '-2011002011': 'recommendersUpdateRelationshipInvalid', // Значение ключа(ей) "relationship_id" слишком велико
  '-2011002012': 'recommendersUpdateRelationshipInvalid', // Значение ключа(ей) "relationship_id" слишком мало
  '-2011002013': 'recommendersUpdateRelationshipInfoNotFound', // Значение ключа(ей) "relationship_id" слишком мало

  // RECOMMENDERS PHONE ADD
  '-2013001006': 'recommendersPhoneAddPhoneRequired', // Отсутствует обязательный(ые) ключ(и) "phone"
  '-2013001008': 'recommendersPhoneAddInvalidFormat', // Значение ключа(ей) "phone" имеет неверный формат

  // RECOMMENDERS PHONE UPDATE
  '-2013002005': 'recommendersPhoneUpdateInfoNotFound', // Информация не найдена
  '-2013002011': 'recommendersPhoneUpdateInvalidFormat', // Значение ключа(ей) "phone" имеет неверный формат

  // PROFILE FORM
  'invalid sex_id according to inn parse': 'invalid sex_id according to inn parse',
  'invalid birth_date according to inn parse': 'invalid birth_date according to inn parse',
  'inn is not unique': 'inn is not unique',
  'invalid "record_number"': 'invalid "record_number"',
  'invalid sex according to record_number parse': 'invalid sex according to record_number parse',
  'invalid birth_date according to record_number parse': 'invalid birth_date according to record_number parse',

  // ADDRESS DISTRICT
  '-2016002001': 'districtAddOriginalNameRequired', // Отсутствует обязательный(ые) ключ(и) "original_name",
  '-2016002003': 'districtAddOriginalNameTooLong', // Значение ключа "original_name" превышает максимальную длину,
  '-2016002008': 'districtAddInfoNotFound', // Связанные данные "address_region_id" не найдены,

  // ADDRESS CITY
  '-2015002006': 'cityAddInfoNotFound', // Связанные данные "address_district_id" не найдены
  '-2015002007': 'cityAddOriginalNameRequired', // Отсутствует обязательный(ые) ключ(и) "original_name"
  '-2015002009': 'cityAddOriginalNameTooLong', // Значение ключа "original_name" превышает максимальную длину

  // ADDRESS STREET
  '-2020002001': 'streetAddOriginalNameRequired', // Отсутствует обязательный(ые) ключ(и) "original_name"
  '-2020002003': 'streetAddOriginalNameTooLong', // Значение ключа "original_name" превышает максимальную длину
  '-2020002008': 'streetAddInfoNotFound', // 	Связанные данные "address_city_id" не найдены

  // ADDRESS HOUSE
  '-2018002001': 'houseAddNameRequired', // Отсутствует обязательный(ые) ключ(и) "name"
  '-2018002003': 'houseAddNameTooLong', // Значение ключа "name" превышает максимальную длину
  '-2018002008': 'houseAddInfoNotFound', // Связанные данные "address_street_id" не найдены

  // ADDRESS FLAT
  '-2017002001': 'flatAddNameRequired', // Отсутствует обязательный(ые) ключ(и) "name"
  '-2017002003': 'flatAddNameTooLong', // Значение ключа "name" превышает максимальную длину
  '-2017002008': 'flatAddInfoNotFound', // Связанные данные "address_house_id" не найдены

  // USER UPLOAD PROFILE FILE
  '-2008001007': 'userProfileFileInvalidFileType',

  // SIGN CREDIT PASSPORT
  '-2006013006': 'signCreditPassportCodeRequired',
  '-2006013008': 'signCreditPassportCodeInvalid',
  '-2006013010': 'signCreditPassportInfoNotFound',
  '-2006013011': 'signCreditPassportAlreadySigned',

  // NOTIFY FOR SIGN CREDIT PASSPORT
  '-2006012005': 'notifyForSignCreditPassportInfoNotFound',
  '-2006012006': 'notifyForSignCreditPassportInfoNotFound',
  '-2006012007': 'notifyForSignCreditPassportApplicationStatusInvalid',
  '-2006012008': 'notifyForSignCreditPassportAlreadySigned',

  // UPLOAD SIGNED FILE
  '-2008003007': 'uploadSignedFileAuthKeyInvalid',
  '-2008003008': 'uploadSignedFileSignatureInvalid',
  '-2008003009': 'uploadSignedFileInnInvalid',
  '-2008003010': 'uploadSignedFileMimTypesInvalid',
  '-2008003011': 'uploadSignedFileUserFileNotFound',

  // USER AUTH
  '-2001005001': 'userAuthInfoNotFound', // Информация не найдена
  '-2001005002': 'userAuthPhoneRequired', // Отсутствует обязательный(ые) ключ(и) "phone"
  '-2001005003': 'userAuthPasswordRequired', // Отсутствует обязательный(ые) ключ(и) "password"
  '-2001005004': 'userAuthInfoNotFound', // Информация не найдена
  '-2001005005': 'userAuthInvalidPassword', // Не правильный пароль
  '-2001005020': 'userAuthCodeRequestedToOften', // Код авторизации запрашивается слишком часто
  '-2001005032': 'userAuthCodeRequestedToOften', // Код авторизации запрашивается слишком часто
  '-2001005021': 'userAuthTooManyCodes', // Слишком много кодов запрошено за последнее время
  '-2001005029': 'userAuthBlockedUser', // Возможность входа для пользователя заблокирована
  '-2001005030': 'userAuthWebsocketNotAvailable', // Вебсокет недоступен

  '-2013001015': 'recommendersPhoneAddUniq',
  '-2013001014': 'recommendersPhoneAddAlreadyUsed',

  // cashback-payout-on-credit
  '-2039002002': 'cashbackPayoutOnCreditInfoNotFound', // Информация не найдена
  '-2039002008': 'cashbackPayoutOnCreditTooSmall', // Значение ключа(ей) "amount" слишком мало
  '-2039002009': 'cashbackPayoutOnCreditTooBig', // Значение ключа(ей) "amount" слишком велико
  '-2039002010': 'cashbackPayoutOnCreditNegativeBalance', // Баланс "user_cashback" не должен быть отрицательным
  '-2039002011': 'cashbackPayoutOnCreditPercentLessThanAmt', // У кредита остаточный долг процентов меньше значения "amount"
  '-2039002012': 'cashbackPayoutOnCreditPayoutNotAvailable', // Выплата недоступна

  // payout-on-payment-instance
  '-2039003002': 'payoutOnPaymentInstanceInfoNotFound', // Информация не найдена,
  '-2039003008': 'payoutOnPaymentInstanceAmountTooSmall', // Значение ключа(ей) "amount" слишком мало
  '-2039003009': 'payoutOnPaymentInstanceAmountTooBig', // Значение ключа(ей) "amount" слишком мало
  '-2039003010': 'payoutOnPaymentInstanceAmountNegativeBalance', // Значение ключа(ей) "amount" слишком мало
  '-2039003011': 'payoutOnPaymentInstancePayoutNotAvailable', // Значение ключа(ей) "amount" слишком мало
  '-2039003016': 'payoutOnPaymentInstanceOverdueDaysHasBeenExceeded', // Выплата недоступна

  // CASHBACK SEND tO USER

  '-2039004003': 'sendToUserPhoneInvalidFormat', // Значение ключа "phone" не соответствует международному формату"
  '-2039004006': 'sendToUserAmountTooSmall', // Значение ключа(ей) "amount" слишком мало
  '-2039004007': 'sendToUserAmountTooBig', // Значение ключа(ей) "amount" слишком велико
  '-2039004008': 'sendToUserAmountNegativeBalance', // Баланс "user_cashback" не должен быть отрицательным
  '-2039004009': 'sendToUserAmountPayoutNotAvailable', // Выплата недоступна
  '-2039004010': 'sendToUserInfoNotFound', // Баланс "user_cashback" не должен быть отрицательным
  '-2039004011': 'sendToUserInfoNotFound', // 	Выплата недоступна

  // CASHBACK BUY TRX
  '-2039005002': 'buyTrxInfoNotFound', // Информация не найдена
  '-2039005008': 'buyTrxAmountTooSmall', // Информация не найдена
  '-2039005009': 'buyTrxAmountTooBig', // Значение ключа(ей) "amount" слишком велико
  '-2039005010': 'buyTrxUserCashbackNegative', // Баланс "user_cashback" не должен быть отрицательным
  '-2039005011': 'buyTrxAmountNotAvailable', //	Выплата недоступна

  // REQUEST EXCHANGE
  '-2044003005': 'requestExchangeLimitReached',
  '-2044003006': 'requestExchangeTooManyRequest',
  '-2044003007': 'requestExchangeTooManyRequestForLastTime',

  // Request TRANSFER
  '-2041003010': 'requestTransferTooManyRequestForLastTime',
  '-2041003009': 'requestTransferTooManyRequest',

  //USER-INTEGRATION-BANK-ID-NBU
  '-2040002019': 'userIntegrationBankIdNbuLimitExpired',
};
