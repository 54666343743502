import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { validate } from 'crypto-address-validator-ts';
import { omit } from 'lodash';

export class FormValidators {
  static inn(c: AbstractControl) {
    if (!c?.value) return null;
    let fieldValue = c.value;
    fieldValue = fieldValue.split('');

    const control_sum =
      fieldValue[0] * -1 +
      fieldValue[1] * 5 +
      fieldValue[2] * 7 +
      fieldValue[3] * 9 +
      fieldValue[4] * 4 +
      fieldValue[5] * 6 +
      fieldValue[6] * 10 +
      fieldValue[7] * 5 +
      fieldValue[8] * 7;

    if (Number(fieldValue[9]) !== (control_sum % 11) % 10) {
      return {
        invalidInn: true,
      };
    }

    return null;
  }

  static phone(control: AbstractControl) {
    if (!control?.value) return null;
    const regExp = /\+[0-9]{2}\s\(\d+\)\s\d{3}\s\d{2}\s\d{2}|\+[0-9]{12}/g;
    if (!regExp.test(control?.value)) {
      return {
        invalidPhone: true,
      };
    }
    return null;
  }

  static cyrillicLetters(control: AbstractControl) {
    const value = control?.value;
    if (!value) return null;
    const regExp = /^[а-яА-ЯёЁїЇіІєЄґҐ'-/\s]+$/;
    if (!regExp.test(value)) {
      return {
        onlyCyrillic: true,
      };
    }
    return null;
  }

  static addressAutocompleteValidator(control: AbstractControl) {
    const value = control?.value;
    if (!value) return null;
    const regExp = /^[а-яА-ЯёЁїЇіІєЄґҐ'\s\^&*0-9)(.-]*$/;
    if (!regExp.test(value)) {
      return {
        invalidAddressChar: true,
      };
    }
    return null;
  }

  static password(control: AbstractControl) {
    const value = control?.value;
    if (!value) return null;
    const letterRegExp = /^[a-zA-Z0-9!№;%:?*()_+@#$^&-=`~']+$/;
    if (!letterRegExp.test(value)) {
      return {
        latinCharacters: true,
      };
    }

    if (!new RegExp('[A-Z]').test(value)) {
      return {
        upperCaseCharacter: true,
      };
    }

    if (!new RegExp('[0-9]').test(value)) {
      return {
        numericCharacter: true,
      };
    }

    return null;
  }

  static email(control: AbstractControl) {
    const value = control?.value;
    if (!value) return null;
    const regExp = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
    if (!value.match(regExp)) {
      return {
        invalidEmail: true,
      };
    }
    return null;
  }

  static sameControlsValue(secondControlName: string) {
    return (control: AbstractControl) => {
      const controlValue = control?.value;
      const secondControl = (control?.parent as FormGroup)?.controls[secondControlName];
      const secondControlValue = secondControl?.value;

      if (!controlValue || !secondControlValue) return null;
      if (controlValue !== secondControlValue)
        return {
          passwordsMismatch: true,
        };
      return null;
    };
  }

  static nameValidator(control: AbstractControl) {
    const regExp = /(^$)|(^[а-яА-ЯёЁїЇіІєЄґҐ'\\s]+$)/;
    const value = control.value;
    if (!value) return null;
    if (!regExp.test(value)) {
      return {
        invalidName: true,
      };
    }
    return null;
  }

  static addressHouse(control: AbstractControl) {
    const regExp = /(^$)|(^[1-9]\d*(([а-яА-ЯёЁїЇіІєЄґҐ/-d]*)?)$)/;
    const value = control.value;
    if (!value) return null;
    if (!regExp.test(value)) {
      return {
        invalidHouse: true,
      };
    }
    return null;
  }

  static passportSerial(control: AbstractControl) {
    const regExp = /[А-ЯІ]{2}/u;
    const value = control.value;
    if (!value) return null;
    if (!regExp.test(value)) {
      return {
        invalidSerial: true,
      };
    }
    return null;
  }

  static passportIdRecordNum(control: AbstractControl) {
    const regExp = /(^$)|(^(\d{8}-\d{5})$)/g;
    const value = control.value;
    if (!value) return null;
    if (!regExp.test(value)) {
      return {
        invalidRecordNumber: true,
      };
    }
    return null;
  }

  static creditCardVerify(value: boolean) {
    return () => {
      if (value) return null;
      if (!value) {
        return {
          isNotVerifiedCard: true,
        };
      }
      return null;
    };
  }

  static creditCardNumber(control: AbstractControl) {
    if (!control?.value) return null;
    const value = (control.value as string)?.replaceAll(' ', '');

    let sum = 0;
    let mul = 1;
    const l = value.length;
    for (let i = 0; i < l; i++) {
      const digit = value.substring(l - i - 1, l - i);
      const tproduct = parseInt(digit, 10) * mul;
      if (tproduct >= 10) sum += (tproduct % 10) + 1;
      else sum += tproduct;
      if (mul === 1) {
        mul = mul + 1;
      } else {
        mul = mul - 1;
      }
    }

    if (sum % 10 === 0) {
      return null;
    } else {
      return { invalidCreditCardNumber: true };
    }
  }

  static creditCardType(control: AbstractControl) {
    if (!control?.value) return null;
    const value = control.value;
    if (!(value.match(/^6/) || value.match(/^5[1-5]/) || value.match(/^4/))) {
      return {
        invalidCreditCardType: true,
      };
    }

    return null;
  }

  static selectAtLeastOnePhone(control: AbstractControl) {
    const formArray = control as FormArray;
    const value = formArray.getRawValue() as { phone: string; phone2: string }[];
    const isValidValue = value.some((v) => v.phone || v.phone2);
    return !isValidValue ? { selectAtLestOnePhone: true } : null;
  }

  static requiredValue(control: AbstractControl) {
    const value = Boolean(control?.value);
    if (!value) {
      return {
        requiredValue: true,
      };
    }
    return null;
  }

  static passportBookNumber(control: AbstractControl) {
    const regExp = /[0-9]{6}/u;
    const value = control.value;
    if (!value) return null;
    if (!regExp.test(value)) {
      return {
        invalidPassportSerial: true,
      };
    }
    return null;
  }

  static passportIdNumber(control: AbstractControl) {
    const regExp = /^\d{9}$/;
    const value = control.value;
    if (!value) return null;
    if (!regExp.test(value)) {
      return {
        invalidPassportSerial: true,
      };
    }
    return null;
  }

  static date(control: AbstractControl) {
    const value = control.value;
    const regEx = /(0?[1-9]|[12][0-9]|3[01])[.-](0?[1-9]|1[012])[.-]\d{4}$/;
    if (regEx?.test(value)) {
      return {
        invalidDate: true,
      };
    }
    return null;
  }

  static passportIssueBy(control: AbstractControl) {
    const regExp = /^\d{4}$/;
    const value = control.value;
    if (!value) return null;
    if (value?.length < 4) {
      return {
        invalidPassportIssueBy: true,
      };
    }
    return null;
  }

  static addressAutocomplete(control: AbstractControl) {
    const value = control.value;
    if (value && typeof value !== 'number') {
      return {
        valueMustBeChoosen: true,
      };
    }
    return null;
  }

  static cryptoWalletValidator(control: AbstractControl) {
    const value = control.value;
    const trxValid = value && validate(value, 'trx', { networkType: 'both', chainType: '' });
    if (value && !trxValid) {
      return {
        cryptoWalletInvalid: true,
      };
    }
    return null;
  }

  static twoControlsValueMatch(firstControlName: string, secondControlName: string) {
    return (control: AbstractControl) => {
      const firstControl = (control as FormGroup)?.get(firstControlName);
      const secondControl = (control as FormGroup)?.get(secondControlName);
      const firstControlErrors = omit(firstControl?.errors ?? null, ['valueMatch']);
      const secondControlErrors = omit(secondControl?.errors ?? null, ['valueMatch']);
      const hasErrorFirstControl = !!Object.keys(firstControlErrors ?? {}).length;
      const hasErrorSecondControl = !!Object.keys(secondControlErrors ?? {}).length;

      if (!firstControl?.value || !secondControl?.value) {
        firstControl?.setErrors(hasErrorFirstControl ? { ...firstControlErrors } : null, { emitEvent: true });
        secondControl?.setErrors(hasErrorSecondControl ? { ...secondControlErrors } : null, { emitEvent: true });
        return null;
      }

      if (firstControl?.value === secondControl?.value) {
        firstControl?.setErrors({ ...firstControlErrors, valueMatch: true }, { emitEvent: true });
        secondControl?.setErrors({ ...secondControlErrors, valueMatch: true }, { emitEvent: true });
        return { valueMatch: true };
      }

      firstControl?.setErrors(hasErrorFirstControl ? { ...firstControlErrors } : null, { emitEvent: true });
      secondControl?.setErrors(hasErrorSecondControl ? { ...secondControlErrors } : null, { emitEvent: true });
      return null;
    };
  }

  static fullName(control: AbstractControl) {
    const value = control?.value;
    const data = (value ?? '')?.trim()?.split(' ');
    if (!value) return null;
    if (data?.length < 3 || data?.length > 3) {
      return {
        invalidFullName: true,
      };
    }

    return null;
  }
}
