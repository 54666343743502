import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ERROR_RESPONSE_CODE, INN_MASK, PHONE_TEXT_MASK } from '@core/constants';
import {
  IAddressItem,
  IAddressOption,
  IContactValue,
  ICreditCalculationItem,
  IErrorResponseItem,
  IOptionItem,
  ISettings,
} from '@core/interfaces';
import { TextMaskConfig } from 'angular2-text-mask';
import * as moment from 'moment/moment';
import { Moment } from 'moment/moment';
import { STORAGE_CONSTANT } from '../constants';
import { ApplicationStatusEnum, ControlSettingsNameEnum, CreditStatus, DocumentTemplateTypeEnum } from '../enums';
import { IApplication, IControlSetting, IEnvironment, IProlongationData } from '../interfaces';
import { FormValidators } from '../validators';

export function mapErrors(controlKeys: string[], errors: IErrorResponseItem[]) {
  const errorItems = [...errors];
  controlKeys?.forEach((controlKey) => {
    const e = errors.find((item) => item?.message?.includes(controlKey));
    const error = getErrorByKey(controlKey);
    if (e && error) errorItems.push(error);
  });
  return errorItems;
}

function getErrorByKey(key: string): IErrorResponseItem | null {
  switch (key) {
    case 'phone':
      return { code: -10000100, message: 'Невірний тип телефону' };
    default:
      return null;
  }
}

export function getMaskByFieldName(fieldName?: ControlSettingsNameEnum): TextMaskConfig {
  switch (fieldName) {
    case ControlSettingsNameEnum.Phone:
      return PHONE_TEXT_MASK;
    case ControlSettingsNameEnum.OldPhone:
      return PHONE_TEXT_MASK;
    case ControlSettingsNameEnum.NewPhone:
      return PHONE_TEXT_MASK;
    case ControlSettingsNameEnum.Inn:
      return INN_MASK;
    default:
      return { mask: false };
  }
}

export function errorCase(fieldName: ControlSettingsNameEnum | undefined): string[] {
  switch (fieldName) {
    case ControlSettingsNameEnum.Phone:
      return [
        'loginInfoNotFound',
        'loginBlockedUser',
        'passwordResetCodePhoneRequired',
        'passwordResetCodeInformationNotFound',
        'passwordResetCodeTooManyCodes',
        'passwordResetCodeInterval',
        'loginToManyRequests',
        'loginCodeRequestedToOften',
        'loginCodeToManyRequests',
        'loginWebSocketNotAvailable',
        'recommendersPhoneAddPhoneRequired',
        'recommendersPhoneAddInvalidFormat',
        'recommendersPhoneUpdateInfoNotFound',
        'recommendersPhoneUpdateInvalidFormat',
        'userAuthInfoNotFound',
        'userAuthPhoneRequired',
        'userAuthCodeRequestedToOften',
        'userAuthTooManyCodes',
        'userAuthBlockedUser',
        'userAuthWebsocketNotAvailable',
        'userRegistrationPhoneRequired',
        'userRegistrationPhoneInvalid',
        'userRegistrationPhoneIsUsed',
      ];
    case ControlSettingsNameEnum.OldPhone:
      return ['recoveryPasswordOldPhoneRequired', 'recoveryPasswordOldPhoneInvalid'];
    case ControlSettingsNameEnum.NewPhone:
      return ['recoveryPasswordNewPhoneRequired', 'recoveryPasswordNewPhoneInvalid'];
    case ControlSettingsNameEnum.Password:
      return [
        'loginInvalidPassword',
        'loginIncorrectPasswordValue',
        'loginPasswordRequired',
        'registrationPasswordRequired',
        'registrationPasswordTooShort',
        'registrationPasswordTooLong',
        'registrationPasswordNeedNumber',
        'registrationPasswordBothCase',
        'registrationPasswordInvalidFormat',
      ];
    case ControlSettingsNameEnum.RemindPasswordPhone:
      return [''];
    case ControlSettingsNameEnum.Code:
      return [
        'passwordResetCodeRequired',
        'passwordResetPasswordRequired',
        'passwordResetInformationNotFound',
        'passwordResetInvalidCode',
        'authVerifyInformationNotFound',
        'authVerifyInvalidCode',
        'authVerifyBlockedUser',
      ];
    case ControlSettingsNameEnum.FirstName:
      return [
        'invalidName',
        'recommendersUpdateFirstNameTooLong',
        'recommendersAddFirstNameRequired',
        'recommendersAddFirstNameTooLong',
      ];
    case ControlSettingsNameEnum.Inn:
      return [
        'inn is not unique',
        'recoveryPasswordInnRequired',
        'recoveryPasswordInnTooLong',
        'recommendersAddSurnameRequired',
        'recommendersAddSurnameTooLong',
      ];
    case ControlSettingsNameEnum.Surname:
      return [
        'invalidSurname',
        'recommendersUpdateSurnameTooLong',
        'recommendersAddSurnameRequired',
        'recommendersAddSurnameTooLong',
      ];
    case ControlSettingsNameEnum.SexId:
      return ['invalid sex_id according to inn parse', 'invalid sex according to record_number parse'];
    case ControlSettingsNameEnum.BirthDate:
      return ['invalid birth_date according to inn parse', 'invalid birth_date according to record_number parse'];
    case ControlSettingsNameEnum.PassportIssuedBy:
      return ['missingPassportIssuedBy'];
    case ControlSettingsNameEnum.PassportRecordId:
      return ['missingPassportRecordNumber', 'invalid "record_number"'];
    case ControlSettingsNameEnum.PassportNumberId:
      return ['missingPassportNumber'];
    case ControlSettingsNameEnum.RelationshipId:
      return [
        'recommendersAddRelationshipRequired',
        'recommendersAddRelationshipInvalid',
        'recommendersAddRelationshipInfoNotFound',
        'recommendersUpdateRelationshipInvalid',
        'recommendersUpdateRelationshipInfoNotFound',
      ];
    default:
      return [];
  }
}

export function calculateSumCreditCalculationItem(item: ICreditCalculationItem): number {
  return (item?.accrual ?? 0) - (item?.payed ?? 0) - item?.writeOff;
}

export function parseContact(data: IContactValue | undefined): string {
  return (data?.domain ?? '') + (data?.value ?? '');
}

export function errorFileMapping(err: IErrorResponseItem[]): string[] {
  return err.reduce((acc: string[], e) => {
    const key = ERROR_RESPONSE_CODE[e.code];
    if (key) acc.push(key);
    return acc;
  }, []);
}

export function errorDetailMapping(errDetail?: Record<string, string[]>): IErrorResponseItem[] {
  const keys = Object.keys(errDetail ?? {});
  return (keys ?? []).reduce((acc: IErrorResponseItem[], key) => {
    const errs = (errDetail ?? {})[key] ?? [];
    if (Array.isArray(errs)) {
      errs.forEach((code) => acc.push({ code: code as unknown as number, message: '' }));
    } else {
      acc.push({ code: errs as unknown as number, message: '' });
    }
    return acc;
  }, []);
}

export function errorFieldsDetailsMapping(e: IErrorResponseItem[]): IErrorResponseItem[] {
  return (e ?? [])?.reduce((acc: IErrorResponseItem[], item) => {
    const detailErrors = errorDetailMapping(item?.details);
    acc = [...acc, item, ...(detailErrors ?? [])];
    return acc;
  }, []);
}

export function serializeAddressItemData(item: IAddressItem | IAddressOption): IAddressItem {
  return {
    name: item?.original_name || item?.international_name || item?.name || '',
    id: item.id,
  };
}

export function parsePhone(phone: string): string {
  return (phone ?? '').replaceAll(new RegExp(/[\s()]/g), '');
}

export function generateControlOptions(d: Record<number | string, string | number> | unknown): IOptionItem[] {
  if (!d || !Object.keys(d ?? {})?.length) return [];
  return Object.entries(d).map((item) => ({ value: item[0], title: item[1] }));
}

export function recalculateDate(days: number | null): Date {
  const date = new Date();
  const calcDate = moment(date, 'DD.MM.YYYY').add(days ?? 0, 'days');
  return calcDate.toDate();
}

export function parseDate(date: string | Moment | Date): string | null {
  if (date instanceof moment) {
    return (date as Moment)?.format('YYYY-MM-DD');
  }
  if (typeof date === 'string') {
    return moment(date).format('YYYY-MM-DD');
  }

  if (date instanceof Date) {
    return moment(date).format('YYYY-MM-DD');
  }
  return null;
}

export function markControlsAsTouched(form: FormGroup): void {
  if (!form) return;
  const keys = Object.keys(form.controls);
  keys.forEach((key) => form.get(key)?.markAsTouched());
}

export function isEnabledTwoFactor(settings: ISettings): boolean {
  const isPasswordEnabledOnTwoFactorAuth = settings?.settings?.user?.isPasswordEnabledOnTwoFactorAuth as boolean;
  const twoFactorAuthEnabled = settings?.settings?.user?.twoFactorAuthEnabled as boolean;
  return !twoFactorAuthEnabled || (isPasswordEnabledOnTwoFactorAuth && twoFactorAuthEnabled);
}

export function calculateCalculationItem(item?: ICreditCalculationItem): number {
  return (item?.accrual ?? 0) - (item?.payed ?? 0) - (item?.writeOff ?? 0);
}

export class ControlErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: AbstractControl | null): boolean {
    return !!control?.errors && control?.touched;
  }
}

export function blobToBase64(data: Blob): Promise<string | null> {
  return new Promise<string | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(<string>reader.result);
    reader.onerror = () => reject(null);
    reader.readAsDataURL(data as Blob);
  });
}

export function getValidatorByControlType(controlData: IControlSetting, settings?: ISettings): ValidatorFn[] {
  switch (controlData.field) {
    case ControlSettingsNameEnum.Password:
      return [
        FormValidators.password,
        Validators.minLength(settings?.settings?.user?.passwordConditions?.passwordMinLength ?? 6),
        Validators.maxLength(settings?.settings?.user?.passwordConditions?.passwordMaxLength ?? 20),
      ];
    case ControlSettingsNameEnum.RepeatPassword:
      return [FormValidators.sameControlsValue('password')];
    case ControlSettingsNameEnum.FirstName:
      return [
        FormValidators.nameValidator,
        FormValidators.cyrillicLetters,
        Validators.minLength(2),
        Validators.maxLength(255),
      ];
    case ControlSettingsNameEnum.MiddleName:
      return [
        FormValidators.nameValidator,
        FormValidators.cyrillicLetters,
        Validators.minLength(2),
        Validators.maxLength(255),
      ];
    case ControlSettingsNameEnum.Surname:
      return [
        FormValidators.nameValidator,
        FormValidators.cyrillicLetters,
        Validators.minLength(2),
        Validators.maxLength(255),
      ];
    case ControlSettingsNameEnum.Inn:
      return [FormValidators.inn];
    default:
      return [];
  }
}

export function getActiveApplications(applications: IApplication[]): IApplication[] {
  return applications.filter(
    (c) =>
      c.status === ApplicationStatusEnum.WaitingMoney ||
      c.status === ApplicationStatusEnum.Approved ||
      c.status === ApplicationStatusEnum.Pending,
  );
}

export function hasSoldCredit(applications: IApplication[]): boolean {
  return !!applications.find((app) => app?.credit?.status === CreditStatus.Sold);
}

export function getClosedCredits(applications: IApplication[]): IApplication[] {
  return applications.filter((app) => app?.credit?.status === CreditStatus.Closed);
}

export function concatPhone(domain: string, value: string): string {
  return `${domain ?? ''}${value ?? ''}`;
}

export function concatEmail(value: string, domain: string): string {
  return `${value ? value + '@' + domain : ''}`;
}

export function contactConcat(data: IContactValue) {
  return data?.domain?.includes('.')
    ? concatEmail(data?.value ?? '', data?.domain ?? '')
    : concatPhone(data?.domain ?? '', data?.value ?? '');
}

export function generateProlongationData(interval: number, prolongationData: IProlongationData | null) {
  let currentInterval = interval;
  let amount = 0;
  if (currentInterval > (prolongationData?.maxInterval ?? 0)) currentInterval = prolongationData?.maxInterval ?? 0;
  if (currentInterval < (prolongationData?.minInterval ?? 0)) currentInterval = prolongationData?.minInterval ?? 0;
  if (prolongationData?.intervals.length) {
    currentInterval =
      (prolongationData?.intervals ?? []).find((day) => day?.interval >= currentInterval)?.interval ?? 0;
    if (currentInterval > (prolongationData?.maxInterval ?? 0)) currentInterval = prolongationData?.maxInterval ?? 0;
    if (currentInterval < (prolongationData?.minInterval ?? 0)) currentInterval = prolongationData?.minInterval ?? 0;
    amount = prolongationData?.intervals?.find((item) => item.interval === currentInterval)?.to_pay ?? 0;
  }
  return { interval: currentInterval, amount };
}

export function generateDocumentLink(
  application: IApplication,
  type: DocumentTemplateTypeEnum,
  env: IEnvironment,
): string {
  const token = JSON.parse(localStorage.getItem(STORAGE_CONSTANT.TOKEN) ?? '');
  return (
    env.apiUrl +
    `user/document/view?application_id=${application?.id ?? ''}&document_template_type=${type}&token=${token}`
  );
}

export function copyValue(data: string): void {
  navigator?.clipboard?.writeText(data);
}
