export enum ClassificatorTypeEnum {
  TYPE_EDUCATION = 1,
  TYPE_WORK_COMPANY = 2,
  TYPE_WORK_COMPANY_SCOPE = 3,
  TYPE_WORK_POST = 4,
  TYPE_ADDITIONAL_INCOME_SOURCE = 5,
  TYPE_MILITARY = 6,
  TYPE_MILITARY_RANK = 7,
  TYPE_KVED = 8,
  TYPE_COMMERCIAL_PROPERTY = 9,
  TYPE_SECRET_QUESTIONS = 10,
  TYPE_MONTHLY_PAYMENTS_ON_CREDITS = 11,
  TYPE_CREDIT_PURPOSES = 12,
  TYPE_SOCIAL_STATUSES = 13,
  TYPE_PASSPORT = 14,
  TYPE_SEX = 15,
  TYPE_BLOCK_PHONE_REASONS = 16,
  TYPE_BLACK_LIST_BLOCKING_REASON = 17,
  TYPE_BLACK_LIST_SOURCE = 18,
  TYPE_USER_RECOMMENDER_RELATIONSHIP = 19,
}
