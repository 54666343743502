export enum PaymentInstanceCardTypeEnum {
  Mastercard = 1,
  Visa = 2,
  Jcb = 3,
  Amex = 4,
  DinersClub = 5,
  VPreca = 6,
  Postpay = 7,
  Cartasi = 8,
  Entropay = 9,
  O2money = 10,
  Kukuruza = 11,
  Yunacard = 12,
  Discover = 13,
  Maestro = 14,
  Unknown = 0,
}
