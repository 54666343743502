export const STORAGE_CONSTANT: Record<string, string> = {
  TOKEN: 'token',
  AFTER_LOGIN: 'after_login',
  CAN_USE_CASHBACK: 'can_use_cashback',
  REGISTRATION_LOGIN: 'registration_login',
  REGISTRATION_PASSWORD: 'registration_password',
  VERIFICATION_TYPE: 'auth_verification_type',
  SKIP_BANK_ID_STEP: 'skip_bank_id_step',
  REFERRAL_LINK: 'referral',
  UID: 'uid',
  CALL_STATUS: 'call_status',
  USER: 'user',
  USER_ID: 'user_id',
  WS_DATA: 'ws_data',
  SETTINGS: 'settings',
  SOLD_STATUS: 'sold_status',
  HAS_OPEN_CREDIT: 'has_open_credit',
  AVAILABLE_MOBILE_APP: 'available_mobile_app',
  LOG_ID: 'log_id',
  TIMESTAMP_LOG_ID: 'log_id_timestamp',
  AUTH_REPEAT_CODE_INTERVAL: 'auth_repeat_code_interval',
  REPEAT_REG_CODE_INTERVAL: 'repeat_reg_code_interval',
  LOGIN_CODE_INTERVAL: 'login_code_interval',
  VERIFY_REVISION_PHONE: 'verify_revision_phone',
  VERIFY_CARD_INTERVAL: 'verify_card_interval',
  CALL_TIMER_DATA: 'call_timer_data',
  REPEAT_AUTH_CODE: 'repeat_auth_code',
  REPEAT_RESTORE_PASSWORD_CODE: 'repeat_restore_password_code'
};
