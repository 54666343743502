export enum MasterStep {
  LK = 0,
  /** шаг синхронизации данных с BankID;*/
  BankId = 1,
  /** шаг заполнения ФИО и ИНН;*/
  Inn = 2,
  /** шаг заполнения данных о занятости;*/
  Employments = 3,
  /** шаг заполнения персональных данных;*/
  PersonalData = 4,
  /** шаг заполнения данных фактического адреса и адреса регистрации;*/
  Address = 5,
  /** шаг подачи заявки (на этом шаге также добавляются кредитная карта и скан-копии
   документов);*/
  Application = 6,
  /** шаг подтверждения кредита*/
  ApplicationApproved = 7,
  /** шаг добавления секретного вопроса и ответа;*/
  SecretQuestion = 8,
  /** шаг ожидания решения по заявке;*/
  Waiting = 9,
  /** шаг доработки заявки;*/
  FinalizationApplication = 10,
  /** шаг одобренной заявки;*/
  ApplicationApprove = 11,
  /** шаг отклонённой заявки;*/
  ApplicationRejected = 12,
  /** шаг ожидания выдачи денег;*/
  PaymentWaiting = 13,

  Sold = 14,

  VerifyByBankId = 15,
}
