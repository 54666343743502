export enum WebsocketMessageTypeEnum {
  /**Тип даних довільний*/
  TypeCustom = 1,
  /** Тип даних для верифікації користувача */
  TypeAuthVerifyCodeSource = 2,
  /** Тип даних для зміни статусу заявки */
  TypeApplicationStatusChanged = 6,
  /** Тип даних для авторизації користувача після успішної верифікації дзвінком */
  TypeUserAuthByCall = 8,
  /** Відправка для конкретного UID*/
  TypeCreditStatusChanged = 9,
  /** Тип даних для перенаправлення користувача на вказану URL адресу*/
  TypeRedirectUrl = 10,

  TypeChangeCryptoCurrency = 11,
}
