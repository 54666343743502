export enum WsPaymentStatus {
  /** Неизвестно */
  Unknown = 1,
  /** Успешная оплата */
  Success = 2,
  /** Ошибка платежа */
  Error = 3,
  /** Платеж отклонен */
  Rejected = 4,
  /** Платеж просрочен */
  Expired = 5,
  /** Платеж в обработке */
  Processing = 6,
  /** Платеж в обработке */
  Duplicate = 7,
}
