import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ProfileDataState, ProfileDataStore } from './profile-data.store';
import { map, take } from 'rxjs';
import * as _ from 'lodash';


@Injectable({ providedIn: 'root' })
export class ProfileDataQuery extends QueryEntity<ProfileDataState> {

  getControlByField$ = (key: string) => this.selectEntity(key);

  getFormFields$ = () => this.selectAll().pipe(
    map(data => _.cloneDeep(data ?? [])),
    take(1),
  );

  constructor(protected override store: ProfileDataStore) {
    super(store);
  }


}
