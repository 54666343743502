import { ControlSettingsNameEnum } from '../enums';

export const ControlSettingNameMap = new Map([
  [ControlSettingsNameEnum.AdditionalIncomeAmount, 'CONTROL_TYPES.additionalIncomeAmount'],
  [ControlSettingsNameEnum.AdditionalIncomeSourceId, 'CONTROL_TYPES.additionalIncomeSourceId'],
  [ControlSettingsNameEnum.IncomeAmount, 'CONTROL_TYPES.incomeAmount'],
  [ControlSettingsNameEnum.MilitaryRank, 'CONTROL_TYPES.militaryRank'],
  [ControlSettingsNameEnum.MilitaryType, 'CONTROL_TYPES.militaryType'],
  [ControlSettingsNameEnum.ActualAddressRegionId, 'CONTROL_TYPES.actualAddressRegionId'],
  [ControlSettingsNameEnum.ActualAddressCityId, 'CONTROL_TYPES.actualAddressCityId'],
  [ControlSettingsNameEnum.ActualAddressDistrictId, 'CONTROL_TYPES.actualAddressDistrictId'],
  [ControlSettingsNameEnum.SocialAddressRegionId, 'CONTROL_TYPES.socialAddressRegionId'],
  [ControlSettingsNameEnum.WorkPostId, 'CONTROL_TYPES.workPostId'],
  [ControlSettingsNameEnum.WorkPhoneId, 'CONTROL_TYPES.workPhoneId'],
  [ControlSettingsNameEnum.WorkCompanyTypeId, 'CONTROL_TYPES.workCompanyTypeId'],
  [ControlSettingsNameEnum.WorkCompanyScopeId, 'CONTROL_TYPES.workCompanyScopeId'],
  [ControlSettingsNameEnum.WorkCompanyName, 'CONTROL_TYPES.workCompanyName'],
  [ControlSettingsNameEnum.Surname, 'CONTROL_TYPES.surname'],
  [ControlSettingsNameEnum.StudyingAtUniversityName, 'CONTROL_TYPES.studyingAtUniversityName'],
  [ControlSettingsNameEnum.StudyingAtFacultyName, 'CONTROL_TYPES.studyingAtFacultyName'],
  [ControlSettingsNameEnum.SocialStatusId, 'CONTROL_TYPES.socialStatusId'],
  [ControlSettingsNameEnum.SocialAddressZipCodeId, 'CONTROL_TYPES.socialAddressZipCodeId'],
  [ControlSettingsNameEnum.SocialAddressStreetId, 'CONTROL_TYPES.socialAddressStreetId'],
  [ControlSettingsNameEnum.SocialAddressHouseId, 'CONTROL_TYPES.socialAddressHouseId'],
  [ControlSettingsNameEnum.SocialAddressFlatId, 'CONTROL_TYPES.socialAddressFlatId'],
  [ControlSettingsNameEnum.SocialAddressDistrictId, 'CONTROL_TYPES.socialAddressDistrictId'],
  [ControlSettingsNameEnum.SocialAddressCountryId, 'CONTROL_TYPES.socialAddressCountryId'],
  [ControlSettingsNameEnum.SocialAddressCityId, 'CONTROL_TYPES.socialAddressCityId'],
  [ControlSettingsNameEnum.SexId, 'CONTROL_TYPES.sexId'],
  [ControlSettingsNameEnum.SelfEmployedKved, 'CONTROL_TYPES.selfEmployedKved'],
  [ControlSettingsNameEnum.SelfEmployedEmployeesCount, 'CONTROL_TYPES.selfEmployedEmployeesCount'],
  [ControlSettingsNameEnum.SelfEmployedCommercialProperty, 'CONTROL_TYPES.selfEmployedCommercialProperty'],
  [ControlSettingsNameEnum.SelfEmployedAverageMonthlyTurnover, 'CONTROL_TYPES.selfEmployedAverageMonthlyTurnover'],
  [ControlSettingsNameEnum.SelfEmployedAverageAnnualTurnover, 'CONTROL_TYPES.selfEmployedAverageAnnualTurnover'],
  [ControlSettingsNameEnum.SecretQuestionId, 'CONTROL_TYPES.secretQuestionId'],
  [ControlSettingsNameEnum.SecretAnswer, 'CONTROL_TYPES.secretAnswer'],
  [ControlSettingsNameEnum.RegistrationAddressZipCode, 'CONTROL_TYPES.registrationAddressZipCode'],
  [ControlSettingsNameEnum.RegistrationAddressStreetId, 'CONTROL_TYPES.registrationAddressStreetId'],
  [ControlSettingsNameEnum.RegistrationAddressRegionId, 'CONTROL_TYPES.registrationAddressRegionId'],
  [ControlSettingsNameEnum.RegistrationAddressHouseId, 'CONTROL_TYPES.registrationAddressHouseId'],
  [ControlSettingsNameEnum.RegistrationAddressFlatId, 'CONTROL_TYPES.registrationAddressFlatId'],
  [ControlSettingsNameEnum.RegistrationAddressDistrictId, 'CONTROL_TYPES.registrationAddressDistrictId'],
  [ControlSettingsNameEnum.RegistrationAddressCountryId, 'CONTROL_TYPES.registrationAddressCountryId'],
  [ControlSettingsNameEnum.RegistrationAddressCityId, 'CONTROL_TYPES.registrationAddressCityId'],
  [ControlSettingsNameEnum.PreviousSurname, 'CONTROL_TYPES.previousSurname'],
  [ControlSettingsNameEnum.PassportTypeId, 'CONTROL_TYPES.passportTypeId'],
  [ControlSettingsNameEnum.PassportSeria, 'CONTROL_TYPES.passportSeria'],
  [ControlSettingsNameEnum.PassportRecordId, 'CONTROL_TYPES.passportRecordNumber'],
  [ControlSettingsNameEnum.PassportNumberId, 'CONTROL_TYPES.passportNumber'],
  [ControlSettingsNameEnum.PassportIssuedBy, 'CONTROL_TYPES.passportIssuedBy'],
  [ControlSettingsNameEnum.PassportIssueDate, 'CONTROL_TYPES.passportIssueDate'],
  [ControlSettingsNameEnum.PassportExpireDate, 'CONTROL_TYPES.passportExpireDate'],
  [ControlSettingsNameEnum.MiddleName, 'CONTROL_TYPES.middleName'],
  [ControlSettingsNameEnum.IsActualAddressSameRegistration, 'CONTROL_TYPES.isActualAddressSameRegistration'],
  [ControlSettingsNameEnum.Inn, 'CONTROL_TYPES.inn'],
  [ControlSettingsNameEnum.FirstName, 'CONTROL_TYPES.firstName'],
  [ControlSettingsNameEnum.EducationId, 'CONTROL_TYPES.educationId'],
  [ControlSettingsNameEnum.BornPlace, 'CONTROL_TYPES.bornPlace'],
  [ControlSettingsNameEnum.BirthDate, 'CONTROL_TYPES.birthDate'],
  [ControlSettingsNameEnum.ActualAddressZipCode, 'CONTROL_TYPES.actualAddressZipCode'],
  [ControlSettingsNameEnum.ActualAddressStreetId, 'CONTROL_TYPES.actualAddressStreetId'],
  [ControlSettingsNameEnum.ActualAddressHouseId, 'CONTROL_TYPES.actualAddressHouseId'],
  [ControlSettingsNameEnum.ActualAddressFlatId, 'CONTROL_TYPES.actualAddressFlatId'],
  [ControlSettingsNameEnum.ActualAddressCountryId, 'CONTROL_TYPES.actualAddressCountryId'],
  [ControlSettingsNameEnum.HaveCar, 'CONTROL_TYPES.haveCar'],
  [ControlSettingsNameEnum.HaveOpenedDeposit, 'CONTROL_TYPES.haveOpenedDeposit'],
  [ControlSettingsNameEnum.KidsCount, 'CONTROL_TYPES.kidsCount'],
  [ControlSettingsNameEnum.LoanPurposeId, 'CONTROL_TYPES.loanPurposeId'],
  [ControlSettingsNameEnum.WorkDirectorName, 'CONTROL_TYPES.workDirectorName'],
  [ControlSettingsNameEnum.FamilyCount, 'CONTROL_TYPES.familyCount'],
  [ControlSettingsNameEnum.MonthlyPaymentOnCreditsId, 'CONTROL_TYPES.monthlyPaymentsOnCreditsId'],
  [ControlSettingsNameEnum.WorkExperience, 'CONTROL_TYPES.workExperience'],
  [ControlSettingsNameEnum.Password, 'CONTROL_TYPES.password'],
  [ControlSettingsNameEnum.RepeatPassword, 'CONTROL_TYPES.repeatPassword'],
  [ControlSettingsNameEnum.Phone, 'CONTROL_TYPES.phone'],
  [ControlSettingsNameEnum.OldPhone, 'CONTROL_TYPES.old_phone'],
  [ControlSettingsNameEnum.NewPhone, 'CONTROL_TYPES.new_phone'],
  [ControlSettingsNameEnum.Comment, 'CONTROL_TYPES.comment'],
  [ControlSettingsNameEnum.Code, 'CONTROL_TYPES.code'],
  [ControlSettingsNameEnum.RemindPasswordPhone, 'CONTROL_TYPES.remindPasswordPhone'],
  [ControlSettingsNameEnum.RelationshipId, 'CONTROL_TYPES.relationship_id'],
]);
