export enum SocialStatusEnum {
  Working = 'working',
  Unemployed = 'unemployed',
  WorkingOfficially = 'WorkingOfficially',
  NotOfficiallyWorking = 'NotOfficiallyWorking',
  Student = 'student',
  GovermentAssistance = 'goverment_assistance',
  SelfEmployed = 'self_employed',
  Pensioner = 'pensioner',
  Military = 'military',
}
