export enum CreditCheckValidation {
  /** Включена ли подача заявки */
  IsApplicationCreationEnabled = 'is_application_creation_enabled',

  /** Не является ли пользователь заблокированным */
  IsNotBlocked = 'is_not_blocked',

  /** Есть ли верифицированные карты у пользователя */
  HaveVerifiedCards = 'have_verified_cards',

  /** Есть ли верифицированные номера телефонов у пользователя */
  HaveVerifiedPhones = 'have_verified_phones',

  /** Если ли у пользователя все документы (загружены все обязательные файлы) */
  HaveDocuments = 'have_documents',

  /** Нет ли у пользователя открытых кредитов */
  DontHaveCredits = 'dont_have_credits',

  /** Нет ли у пользователя открытых заявок */
  DontHaveApplications = 'dont_have_applications',

  /** Все ли данные в профиле заполнены согласно форме достаточной для подачи заявки */
  IsFormFilled = 'is_form_filled',

  /** Есть ли достаточное количество рекомендателей у пользователя */
  HaveRecommenders = 'have_recommenders',

  /** Может ли подать заявку. Результирующий флаг, становиться true если все предидущие флаги true */
  CanCreateApplication = 'can_create_application',
}
