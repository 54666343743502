import { MasterStep } from '@core/enums';

export const navigateStepMap = new Map<MasterStep, string>([
  [MasterStep.LK, '/account'],
  [MasterStep.BankId, '/bank-id'],
  [MasterStep.Employments, '/wizard/profile/step-work-data'],
  [MasterStep.PersonalData, '/wizard/profile/step-personal-data'],
  // [MasterStep.Employments, '/wizard/profile/step-work-data'],
  [MasterStep.Address, '/wizard/profile/step-address-data'],
  [MasterStep.Application, '/wizard/credit/create'],
  [MasterStep.SecretQuestion, '/wizard/secret-question'],
  [MasterStep.Waiting, '/wizard'],
  [MasterStep.FinalizationApplication, '/wizard/finalization'],
  [MasterStep.ApplicationApprove, '/wizard/approved'],
  [MasterStep.PaymentWaiting, '/wizard/credit/waiting_money'],
  [MasterStep.ApplicationRejected, '/wizard/reject'],
  [MasterStep.Sold, '/sold'],
  [MasterStep.VerifyByBankId, '/wizard/verify-by-bank-id'],
]);
