import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@core/environment';
import { IEnvironment } from '@core/interfaces';
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class EncryptionService {
  constructor(@Inject(ENVIRONMENT) private readonly env: IEnvironment) {}

  encryptionPassword(password: string): string {
    const key = CryptoJS.enc.Utf8.parse(this.env.aesPassword);
    const iv = CryptoJS.enc.Utf8.parse(this.env.aesIv);

    const cipher = CryptoJS.AES.encrypt(password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    return cipher?.toString();
  }

  encryptUid(uid: string): string {
    const key = CryptoJS.enc.Utf8.parse(uid);
    const iv = CryptoJS.enc.Utf8.parse(uid);
    return CryptoJS.PBKDF2(key, iv, { keySize: 32 })?.toString();
  }
}
