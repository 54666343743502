import { Injectable } from '@angular/core';
import { IdData, IPaymentInstance, IResponseData } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private readonly apiSvc: ApiService) {}

  paymentLink(body = {}): Observable<IResponseData<{ url: string }>> {
    return this.apiSvc.post('user/credit/get-payment-link', body);
  }

  oneClickPayment(body = {}): Observable<IResponseData<Record<string, unknown>>> {
    return this.apiSvc.post('user/credit/payment-by-token', body);
  }

  getPaymentInstance(): Observable<IResponseData<IPaymentInstance[]>> {
    return this.apiSvc.post('user/user-payment-instance/list', {});
  }

  addPaymentInstance(body = {}): Observable<IResponseData<IdData>> {
    return this.apiSvc.post('user/user-payment-instance/add', body);
  }

  setPaymentSystem(body = {}): Observable<IResponseData<void>> {
    return this.apiSvc.post('user/user/set-payment-system', body);
  }

  setMainPaymentInstance(id: number): Observable<IResponseData<void>> {
    return this.apiSvc.post('user/user-payment-instance/set-main', { id });
  }
}
