export enum CanCreateErrorTypesEnum {
  CanCreateApplication = 'can_create_application',
  DontHaveApplication = 'dont_have_applications',
  DontHaveCredits = 'dont_have_credits',
  HaveDocuments = 'have_documents',
  HaveRecommenders = 'have_recommenders',
  HaveVerifiedCards = 'have_verified_cards',
  HaveVerifiedPhones = 'have_verified_phones',
  IsApplicationCreationEnabled = 'is_application_creation_enabled',
  IsFormFilled = 'is_form_filled',
  IsNotBlocked = 'is_not_blocked',
}
