export enum RevisionReasonTypeEnum {
  /** Заменить поля анкеты в UserProfile */
  ChangeUserProfile = 1,
  /** заменить сканы документов в UserFile */
  ChangeDocumentsScans = 2,
  /** заменить платежное средство */
  ChangePaymentInstance = 3,
  /** заменить номер телефона заявки */
  ChangeApplicationPhone = 4,
  /** верификация через BankID */
  BankIdVerification = 5,
  /** ожидайте звонка менеджера*/
  WaitManagerCall = 6,
  /** загрузить pdf файл от УБКИ*/
  UbkiFile = 7,

  RecommendersNeeded = 8,

  SecretQuestion = 9,

  DocumentSign = 10,

  VideoCall = 11,

  NeedToChangeCryptoWalletToB2p = 12,

  UbkiCode = 13,
}
