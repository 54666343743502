import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private readonly http: HttpClient) {}
  send(data: string) {
    return this.http.post('https://e-groshi.com/sender-eg.php', { message: data });
  }
}
