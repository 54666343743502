// TODO: Need to be delete;
export enum ProfileWorkControlsType {
  /**
   * Тип занятости
   */
  WorkType = 'work_type',
  /**
   * Тип предприятия
   */
  WorkEnterpriseType = 'work_enterprise_type',
  /**
   * Название организации
   */
  WorkName = 'work_name',
  /**
   * ФИО руководителя
   */
  WorkHead = 'work_head',
  /**
   * Название учебного заведения
   */
  EducationName = 'education_name',
  /**
   * Факультет
   */
  EducationFaculty = 'education_faculty',
  /**
   * Адрес
   */
  Address = 'address',
  /**
   * Род занятий
   */
  KindOfActivity = 'kind_of_activity',
  /**
   * Должность
   */
  WorkPost = 'work_post',
  /**
   * Категория воинского звания
   */
  MilitaryRank = 'military_rank',
  /**
   * Тип военской службы
   */
  MilitaryType = 'military_type',
  /**
   * Основная деятельность по КВЭД
   */
  KvedActivity = 'kved_activity',
  /**
   * Коммерческая недвижимость
   */
  CommercialProperty = 'commercial_property',
  /**
   * Количество сотрудников
   */
  NumberOfEmployees = 'number_of_employees',
  /**
   * Среднемесячный оборот
   */
  AverageMonthlyTurnover = 'average_monthly_turnover',
  /**
   * Среднегодовой оборот
   */
  AverageAnnualTurnover = 'average_annual_turnover',
  /**
   * Стаж на последнем месте работы
   */
  WorkExp = 'work_exp',
  /**
   * Контактный телефон организации
   */
  Phone = 'phone',
  /**
   * Уровень дохода/мес.
   */
  IncomeAmount = 'income_amount',
  /**
   * Доп. доход/мес.
   */
  AdditionalIncomeAmount = 'additional_income_amount',
  /**
   * Источник доп. дохода
   */
  AdditionalIncomeSource = 'additional_income_source',
  /**
   * Телефон отдела кадров
   */
  PersonnelDepartmentPhone = 'personnel_department_phone',
  /**
   * Сфера деятельности
   */
  FieldOfActivity = 'field_of_activity',
}
