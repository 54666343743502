import { Injectable } from '@angular/core';
import { IdData, IRecommender, IResponseData } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RecommendersService {
  constructor(private readonly apiSvc: ApiService) {}

  list(body = {}): Observable<IResponseData<IRecommender[]>> {
    return this.apiSvc.post('user/user-recommender/list', body);
  }

  add(body = {}): Observable<IResponseData<IdData>> {
    return this.apiSvc.post('user/user-recommender/add', body);
  }

  update(body = {}): Observable<IResponseData<IdData>> {
    return this.apiSvc.post('user/user-recommender/update', body);
  }

  addPhone(body = {}): Observable<IResponseData<IdData>> {
    return this.apiSvc.post('user/user-recommender-phone/add', body);
  }

  updatePhone(body = {}): Observable<IResponseData<IdData>> {
    return this.apiSvc.post('user/user-recommender-phone/update', body);
  }
}
