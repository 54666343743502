import { Injectable } from '@angular/core';
import { ICalculate, ICreditProduct, IDetailedCalculate, IResponseData } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CreditProductsService {
  constructor(private readonly apiSvc: ApiService) {}

  list(data = {}): Observable<IResponseData<ICreditProduct[]>> {
    const body = {
      ...data,
      with: [
        {
          name: 'creditProductLocales',
          alias: 'cpl',
          join_type: 'LEFT JOIN',
        },
      ],
      alias: 'cp',
      expand: ['creditProductLocales'],
    };
    return this.apiSvc.post('user/credit-product/list', body);
  }

  get(id: number): Observable<IResponseData<ICreditProduct>> {
    const body = {
      id,
      with: [
        {
          name: 'creditProductLocales',
          alias: 'cpl',
          join_type: 'LEFT JOIN',
        },
      ],
      alias: 'cp',
      expand: ['creditProductLocales'],
    };

    return this.apiSvc.post('user/credit-product/get', body);
  }

  calculate(body = {}): Observable<IResponseData<ICalculate>> {
    return this.apiSvc.post('user/credit-product/calculate', body);
  }

  detailedCalculate(body = {}): Observable<IResponseData<IDetailedCalculate>> {
    return this.apiSvc.post('user/credit-product/detailed-calculate', body);
  }
}
