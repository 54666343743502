export enum ControlSettingsNameEnum {
  Surname = 'surname',
  FirstName = 'first_name',
  MiddleName = 'middle_name',
  SexId = 'sex_id',
  BirthDate = 'birth_date',
  Inn = 'inn',
  PassportTypeId = 'passport_type_id',
  PassportRecordId = 'passport_record_number',
  PassportNumberId = 'passport_number',
  PassportSeria = 'passport_seria',
  PassportIssuedBy = 'passport_issued_by',
  PassportIssueDate = 'passport_issue_date',
  PassportExpireDate = 'passport_expire_date',
  EducationId = 'education_id',
  SecretQuestionId = 'secret_question_id',
  SecretAnswer = 'secret_answer',
  PreviousSurname = 'previous_surname',
  BornPlace = 'born_place',
  HaveCar = 'have_car',
  FamilyCount = 'family_count',
  KidsCount = 'kids_count',
  WorkDirectorName = 'work_director_name',
  WorkExperience = 'work_experience',
  HaveOpenedDeposit = 'have_opened_deposit',
  LoanPurposeId = 'loan_purpose_id',
  MonthlyPaymentOnCreditsId = 'monthly_payments_on_credits_id',
  /// ADDRESS
  IsActualAddressSameRegistration = 'is_actual_address_same_registration',
  RegistrationAddressCountryId = 'registration_address_country_id',
  RegistrationAddressRegionId = 'registration_address_region_id',
  RegistrationAddressDistrictId = 'registration_address_district_id',
  RegistrationAddressCityId = 'registration_address_city_id',
  RegistrationAddressStreetId = 'registration_address_street_id',
  RegistrationAddressHouseId = 'registration_address_house_id',
  RegistrationAddressZipCode = 'registration_address_zip_code',
  RegistrationAddressFlatId = 'registration_address_flat_id',

  ActualAddressCountryId = 'actual_address_country_id',
  ActualAddressRegionId = 'actual_address_region_id',
  ActualAddressDistrictId = 'actual_address_district_id',
  ActualAddressCityId = 'actual_address_city_id',
  ActualAddressStreetId = 'actual_address_street_id',
  ActualAddressHouseId = 'actual_address_house_id',
  ActualAddressZipCode = 'actual_address_zip_code',
  ActualAddressFlatId = 'actual_address_flat_id',
  /// WORK PLACE
  SocialStatusId = 'social_status_id',
  WorkCompanyName = 'work_company_name',
  WorkCompanyTypeId = 'work_company_type_id',
  WorkCompanyScopeId = 'work_company_scope_id',
  WorkPostId = 'work_post_id',
  WorkPhoneId = 'work_phone_id',
  IncomeAmount = 'income_amount',
  AdditionalIncomeAmount = 'additional_income_amount',
  AdditionalIncomeSourceId = 'additional_income_source_id',
  SocialAddressCountryId = 'social_address_country_id',
  SocialAddressRegionId = 'social_address_region_id',
  SocialAddressDistrictId = 'social_address_district_id',
  SocialAddressCityId = 'social_address_city_id',
  SocialAddressStreetId = 'social_address_street_id',
  SocialAddressHouseId = 'social_address_house_id',
  SocialAddressZipCodeId = 'social_address_zip_code_id',
  SocialAddressFlatId = 'social_address_flat_id',
  StudyingAtUniversityName = 'studying_at_university_name',
  StudyingAtFacultyName = 'studying_at_faculty_name',
  SelfEmployedKved = 'self_employed_kved',
  SelfEmployedCommercialProperty = 'self_employed_commercial_property',
  SelfEmployedEmployeesCount = 'self_employed_employees_count',
  SelfEmployedAverageMonthlyTurnover = 'self_employed_average_monthly_turnover',
  SelfEmployedAverageAnnualTurnover = 'self_employed_average_annual_turnover',
  MilitaryType = 'military_type',
  MilitaryRank = 'military_rank',
  Phone = 'phone',
  Password = 'password',
  RepeatPassword = 'repeatPassword',
  // CUSTOM CONTROLS
  OldPhone = 'old_phone',
  NewPhone = 'new_phone',
  Comment = 'comment',
  Code = 'code',
  RemindPasswordPhone = 'remind_password_phone',
  RelationshipId = 'relationship_id',
}
