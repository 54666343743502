export const ErrorsMap = new Map<number, string>([
  // LOGIN ERRORS
  [-98, 'systemError'],
  [-1010101, 'emptyLogin'],
  [-1010102, 'emptyPassword'],
  [-1010103, 'userNotFound'],
  [-1010104, 'invalidPassword'],
  [-1010105, 'invalidGeolocationData'],
  // AUTH VERIFY
  [-1010201, 'emptyLogin'],
  [-1010202, 'userNotFound'],
  [-1010203, 'emptyCode'],
  [-1010204, 'invalidCode'],
  [-1010205, 'invalidGeolocationData'],
  // AUTH VERIFY BY CALL
  [-1010901, 'emptyLogin'],
  [-1010902, 'emptyViclId'],
  [-1010903, 'invalidGeolocationData'],
  [-1010904, 'userNotFound'],
  [-1010905, 'unsuccessfulCall'],
  // AUTH VERIFY BY PIN
  [-1011001, 'disabledLoginByPinCode'],
  [-1011002, 'emptyLogin'],
  [-1011003, 'userNotFound'],
  [-1011004, 'emptyPinCode'],
  [-1011005, 'invalidPinCode'],
  [-1011006, 'invalidGeolocationData'],
  // REGISTRATION
  [-1020101, 'invalidPhone'],
  [-1020409, 'cantUsePhone'],
  [-1020102, 'duplicatePhone'],
  [-1020103, 'invalidEmail'],
  [-1020104, 'duplicateEmail'],
  [-1020105, 'invalidGender'],
  [-1020106, 'invalidName'],
  [-1020107, 'passwordMismatch'],
  [-1020108, 'invalidPassword'],
  [-1020109, 'invalidDate'],
  [-1020110, 'invalidControlQuestion'],
  [-1020111, 'invalidTaxNumber'],
  [-1020112, 'duplicateTaxNumber'],
  [-1020113, 'invalidLastName'],
  [-1020114, 'invalidFirstName'],
  [-1020115, 'invalidMiddleName'],
  // VERIFICATION REGISTRATION CODE
  [-1200801, 'contactNotFound'],
  // [-1200802, ''],
  [-1200803, 'notificationNotReceived'],
  [-1200804, 'emptyId'],
  [-1200805, 'userNotFound'],
  // REGISTRATION VERIFY
  [-1020201, 'codeRequired'],
  [-1020202, 'codeExpired'],
  [-1020203, 'invalidCode'],
  // FORGOT PASSWORD
  [-1010301, 'emptyPhoneNumber'],
  [-1010302, 'phoneNotFound'],
]);
