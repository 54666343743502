import { ApplicationStatusEnum } from '@core/enums';

export const ApplicationStatusMap = new Map<ApplicationStatusEnum, string>([
  [ApplicationStatusEnum.Pending, 'Очікуйте розгляду'],
  [ApplicationStatusEnum.Approved, ' Потрібна ваша згода за договором'],
  [ApplicationStatusEnum.WaitingMoney, 'Очікуйте переказу на карту'],
  [ApplicationStatusEnum.Rejected, 'Відхилено'],
  [ApplicationStatusEnum.SentRevision, 'Потрібно оновити інформацію'],
  [ApplicationStatusEnum.ChangeCreditProduct, 'Виберіть умови'],
]);
