import { IEnvironment } from '@core/interfaces';

export const environment: IEnvironment = {
  production: false,
  apiUrl: 'https://backend.test.plutos.fun/', // https://preprod.eg.microf1.net/ backend.test.plutos.fun
  redirectUrl: 'https://mono.test.plutos.fun/',
  
  socket: 'wss://backend.test.plutos.fun:2053',
  aesPassword: 'bAEGJRPPnJbfnCAupQnU32qafGZgHRPy', // 'bAEGJRPPnJbfnCAupQnU32qafGZgHRPy', //'_dptwHc55fB0eHuN3X_fVebYMWUK0pmm', //'9pL437sLiZ2kt5k4G6CNaNnsS62a8iSB',
  aesIv: 'AEayBCiuIjCerFMi', // 'zQHJkLnMjPm9jKI6', // '8m5hXN98m9Y4EjhZ',
  tinyMCEApiKey: '8mvgz2o48od4ayy5xsfvfltod2w03ziegi4h335m4j5d6cd3',
  recaptcha: {
    siteKey: '6Lc6MWcjAAAAABgEAQLoEW7ymc-9jZqJbc-L8esg',
  },
  brandId: '5',
};
