export enum SurveyFieldTypeEnum {
  String = 1,
  Text = 2,
  Number = 3,
  Date = 4,
  DateTime = 5,
  Amount = 6,
  Select = 7,
  MultiSelect = 8,
  EntitySelect = 9,
  Radio = 10,
  Boolean = 11,
  Checkbox = 12,
  File = 13,
}
