import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { DocumentTemplateTypeEnum } from '@core/enums';
import { IDocument } from '@core/interfaces';
import { CreditService, SettingsService } from '@services';
import { forkJoin, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgreementGuard  {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly applicationSvc: CreditService,
    private readonly settingsSvc: SettingsService,
    private readonly router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const applicationId = route.params?.id;
    const isLkState = state?.url?.includes('account');
    const currentPage = state?.url?.split('/')?.slice(-2)[0];

    return forkJoin([
      this.applicationSvc.application(applicationId).pipe(map((r) => r?.data)),
      this.settingsSvc.getSettingsList().pipe(map((r) => r?.data)),
    ]).pipe(
      map(([app, settings]) => {
        const sendAssignCreditPassportCodeOnRequest =
          !!settings?.settings?.application?.sendAssignCreditPassportCodeOnRequest;
        const creditPassportDoc = this.getCreditPassport(app?.documents ?? []);

        if (sendAssignCreditPassportCodeOnRequest && creditPassportDoc && !creditPassportDoc?.is_signed_by_sms) {
          if (currentPage === 'loan-passport') return true;
          const link = isLkState
            ? ['account', 'loan-passport', applicationId]
            : ['wizard', 'credit', 'loan-passport', applicationId];
          this.router.navigate(link);
          return false;
        }

        if (sendAssignCreditPassportCodeOnRequest && creditPassportDoc && creditPassportDoc?.is_signed_by_sms) {
          if (currentPage === 'loan-agreement') return true;
          const link = isLkState
            ? ['account', 'loan-agreement', applicationId]
            : ['wizard', 'credit', 'loan-agreement', applicationId];
          this.router.navigate(link);
          return false;
        }

        return true;
      }),
    );
  }

  getCreditPassport(documents: IDocument[]) {
    return (documents ?? [])?.find((d) => d?.documentTemplate?.type === DocumentTemplateTypeEnum.CreditPassport);
  }
}
