import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly storage = window.localStorage;

  get<T>(key: string): T | null {
    const v = this.storage.getItem(key);
    return v ? JSON.parse(v) : null;
  }

  set<T>(key: string, data: T): void {
    this.storage.setItem(key, JSON.stringify(data));
  }

  delete(key: string): void {
    this.storage.removeItem(key);
  }

  removeKeys(keys: string[]): void {
    keys.forEach((key) => this.delete(key));
  }

  clear(): void {
    this.storage.clear();
  }
}
