export enum DocumentTemplateTypeEnum {
  CreditAgreement = 1,
  OutgoingPaymentOrder = 2,
  IncomingPaymentOrder = 3,
  AdditionalAgreementProlongation = 4,
  AdditionalAgreementRestructuring = 5,
  DetailedCreditCalculationInternal = 6,
  DetailedCreditCalculationExternal = 7,
  CreditClosingInformation = 8,
  CreditPassport = 9,
}
