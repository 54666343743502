import { Injectable } from '@angular/core';
import { ICheckTelegramSubscribe, IResponseData, ITelegramLink } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TelegramService {
  constructor(private readonly apiSvc: ApiService) {}

  getSubscribeLink(): Observable<IResponseData<ITelegramLink>> {
    return this.apiSvc.post('user/user/telegram-subscribe-link');
  }

  unsubscribeTelegram(): Observable<IResponseData<void>> {
    return this.apiSvc.post('user/user/unsubscribe-telegram');
  }

  checkSubscribeTelegram(): Observable<IResponseData<ICheckTelegramSubscribe>> {
    return this.apiSvc.post('user/user/check-subscribe-telegram');
  }
}
