import { Injectable } from '@angular/core';
import { IResponseData, ReferralProgram } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralProgramService {
  constructor(private readonly apiSvc: ApiService) {}

  referralInfo(body = {}): Observable<IResponseData<ReferralProgram>> {
    return this.apiSvc.post('online/public-api/referral/info', body);
  }

  invite(body = {}): Observable<IResponseData<void>> {
    return this.apiSvc.post('user/referral-reward/send-invitations', body);
  }
}
