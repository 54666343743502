import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FacebookMetaPixelService {
  private readonly fbq = (window as any)?.fbq;
  private readonly dataLayerEvent = (window as any)?.dataLayer;

  event(name: string, type: string): void {
    if (this.fbq) this.fbq(name, type);
  }

  dataLayer(data: Record<string, string>) {
    if (this.dataLayerEvent) this.dataLayerEvent?.push(data);
  }
}
