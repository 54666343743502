export enum ControlSettingsType {
  String = 1,

  Select = 2,

  Boolean = 3,

  Date = 8,

  DateAndTime = 9,

  SelectEssence = 11,

  SecSelect = 13,
}
