export enum RegistrationControlName {
  FirstName = 'first_name',
  LastName = 'last_name',
  ThirdName = 'third_name',
  BirthDate = 'birth_date',
  Sex = 'sex',
  Email = 'email',
  SecretQuestion = 'secret_question',
  SecretAnswer = 'secret_answer',
  Inn = 'inn',
  Phone = 'phone',
  Password = 'password',
  Password2 = 'password2',
}
