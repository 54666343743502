import { UserCashbackReasonEnum } from '../enums';

export const UserCashbackReasonMap = new Map<UserCashbackReasonEnum, string>([
  [UserCashbackReasonEnum.CREDIT, 'Позика'],
  [UserCashbackReasonEnum.ENTERING_EMPLOYEE, 'Внесення співробітником'],
  [UserCashbackReasonEnum.CANCELLATION, 'Анулювання'],
  [UserCashbackReasonEnum.ENTERING_CHS, 'Внесення до ЧС'],
  [UserCashbackReasonEnum.RECHARGE_PHONE, 'Поповнення телефону'],
  [UserCashbackReasonEnum.UBKI, 'УБКІ'],
  [UserCashbackReasonEnum.PAYOUT_ON_PAYMENT_INSTANCE, 'Висновок на платіжний засіб'],
  [UserCashbackReasonEnum.ENTERING_EMPLOYEE_SORRY_BONUS, 'Sorry bonus'],
  [UserCashbackReasonEnum.ENTERING_EMPLOYEE_PROMOTIONAL_OFFERS, 'Рекламні пропозиції'],
  [UserCashbackReasonEnum.ENTERING_EMPLOYEE_PROMOTIONALS, 'Акції'],
  [UserCashbackReasonEnum.ENTERING_EMPLOYEE_SORRY_GIVEAWAYS, 'Розіграші'],
  [UserCashbackReasonEnum.ENTERING_EMPLOYEE_SPECIAL_OFFERS, 'Спеціальні пропозиції'],
  [UserCashbackReasonEnum.TRANSFER_BETWEEN_USERS, 'Переказ між користувачами'],
  [UserCashbackReasonEnum.BUY_TRX, 'Покупка TRX'],
]);
