import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ProfileAvatarState {
  avatar: string;
}


export function createInitialState(): ProfileAvatarState {
  return {
    avatar: ''
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'ProfileAvatarStore',
  cache: {
    ttl: 3600000,
  },
  resettable: true,
})
export class ProfileAvatarStore extends Store<ProfileAvatarState> {
  constructor() {
    super(createInitialState());
  }
}
