import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private readonly apiSvc: ApiService) {}

  viewFile(body = {}): Observable<Blob> {
    return this.apiSvc.postFile('user/file/view', body);
  }
}
