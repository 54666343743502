import { Injectable } from '@angular/core';
import { IResponseData, ISettings } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private readonly apiSvc: ApiService) {}

  getSettingsList(body = {}): Observable<IResponseData<ISettings>> {
    return this.apiSvc.post('user/settings/list', body);
  }
}
