export enum AuthResponseCodeEnum {
  /** Стандартный ответ при авторизации */
  StandardResponse = 0,
  /** Двухфакторная авторизация включена. Нужна верификация кода */
  VerifyByCode = 1,
  /** Двухфакторная авторизация включена. Нужна верификация кода */
  VerifyByPinCode = 2,
  /** Номер телефона не верифицирован, необходима верификация номера телефона */
  VerifyPhone = 3,
}
