import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  AddressActualControls,
  AddressRegistrationControls,
  PassportBookControls,
  PassportControlsStep,
  PassportIdControls,
  STORAGE_CONSTANT,
  UnemployedControls,
  WorkingControls,
} from '@core/constants';
import {
  ApplicationStatusEnum,
  ControlSettingsNameEnum,
  CreditStatus,
  MasterStep,
  PassportTypeEnum,
  RecommenderVisibilityTypeEnum,
  SocialStatusEnum,
} from '@core/enums';
import { IApplication, ICheckCreateCredit, IControlSetting, ISettings } from '@core/interfaces';
import { navigateStepMap } from '@core/maps';
import {
  AuthService,
  CreditService,
  RecommendersService,
  SettingsService,
  StorageService,
  UserProfileService,
} from '@services';
import { forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StepGuard {
  constructor(
    private readonly router: Router,
    private readonly creditSvc: CreditService,
    private readonly storageSvc: StorageService,
    private readonly userProfileSvc: UserProfileService,
    private readonly settingsSvc: SettingsService,
    private readonly recommendersSvc: RecommendersService,
    private readonly authSvc: AuthService,
  ) {
  }

  get bankIdSecondStep(): boolean {
    return !!this.storageSvc.get<boolean>(STORAGE_CONSTANT.SKIP_BANK_ID_STEP);
  }

  get enableBankId(): boolean {
    const version = this.storageSvc.get<ISettings>(STORAGE_CONSTANT.SETTINGS);
    return !!version?.settings?.system?.bankIdNbuSettings?.isEnabled;
  }

  get applicationList$(): Observable<{
    isLkStep: boolean;
    isSoldState: boolean;
    applications: IApplication[];
    isFinalizationState: boolean;
  }> {
    return this.creditSvc.applicationsForGuard().pipe(
      map((r) => r?.data ?? []),
      map((data) => {
        const isLkStep: boolean = this.checkIsLKStep(data);
        const isFinalizationState = this.checkIsFinalizationState(data);
        const isSoldState = this.checkSoldState(data);
        this.storageSvc.set<boolean>(STORAGE_CONSTANT.SOLD_STATUS, isSoldState);
        if (isFinalizationState) return { isLkStep, isSoldState, applications: data, isFinalizationState };
        return { isLkStep, isSoldState, applications: data, isFinalizationState };
      }),
    );
  }

  get profileFormData$() {
    return this.userProfileSvc.profileFormData({}).pipe(map((form_rows) => form_rows ?? []));
  }

  get canCreate$() {
    return this.creditSvc.checkCreateCredit().pipe(map((r) => r?.data ?? ({} as ICheckCreateCredit)));
  }

  get settings$() {
    return this.settingsSvc.getSettingsList().pipe(
      map((r) => r?.data),
      tap((d) => this.storageSvc.set<ISettings>(STORAGE_CONSTANT.SETTINGS, d)),
    );
  }

  get user$() {
    return this.authSvc.getUserData({ expand: ['is_required_nbu_validation'] });
  }

  get recommenders$() {
    return this.recommendersSvc
      .list({
        filters: {
          function: 'and',
          where: [
            {
              check: '=',
              key: 'visibility',
              value: RecommenderVisibilityTypeEnum.TypeAll,
            },
          ],
        },
        expand: ['userRecommenderPhones', 'userRecommenderPhones.phone'],
        with: [
          {
            name: 'userRecommenderPhones',
            alias: 'urp',
            join_type: 'LEFT JOIN',
            with: [
              {
                name: 'phone',
                alias: 'urppp',
                join_type: 'LEFT JOIN',
              },
            ],
          },
        ],
      })
      .pipe(
        map((r) => r?.data ?? []),
        map((data) => data.some((rec) => rec?.userRecommenderPhones?.some((p) => Boolean(p)))),
      );
  }

  private checkIsLKStep(data: IApplication[]): boolean {
    return (
      data?.some((c) => {
        const status = c.status === ApplicationStatusEnum.Completed || c.status === ApplicationStatusEnum.Rejected;
        const creditStatus = c?.credit?.status === CreditStatus.Closed || c?.credit?.status === CreditStatus.Open;
        return status || creditStatus;
      }) && Boolean(data?.length)
    );
  }

  private checkSoldState(data: IApplication[]): boolean {
    return data.some((c) => c?.credit?.status === CreditStatus.Sold);
  }

  private checkIsFinalizationState(data: IApplication[]): boolean {
    return data.some((c) => c.status === ApplicationStatusEnum.SentRevision);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const step = route.data.step;

    return of(step).pipe(
      map((v) => v?.data?.step),
      switchMap(() => forkJoin([this.applicationList$, this.user$, this.profileFormData$])),
      switchMap(([data, user, formRows]) => {
        const { isLkStep, isSoldState, isFinalizationState, applications } = data;
        let st = isLkStep ? MasterStep.LK : null;
        if (isFinalizationState) st = MasterStep.FinalizationApplication;
        if (isSoldState) {
          this.storageSvc.set<boolean>(STORAGE_CONSTANT.SOLD_STATUS, isSoldState);
          st = MasterStep.Sold;
        }
        if (!!user?.is_required_nbu_validation && this.enableBankId) {
          st = MasterStep.VerifyByBankId;
        }
        return of({ step: st, applications, formRows });
      }),
      switchMap(({ step, applications, formRows }) => {
        if (step === null && applications[0]) return of(this.getStepByApplicationStatus(applications[0], formRows));
        if (step === null && !applications[0]) return this.handleWizardStep$(applications, formRows);
        return of(step as MasterStep);
      }),
      map((st) => {
        if (st !== step) {
          const link = navigateStepMap.get(st);
          this.router.navigate([link]);
          return false;
        }
        return st === step;

        // return true;
      }),
    );
  }

  private handleWizardStep$(applications: IApplication[], formRows: IControlSetting[]) {
    return forkJoin([this.canCreate$, this.settings$]).pipe(
      map(([canCreate]) => {
        const needDocs = canCreate?.have_documents === false ?? false;
        const haveRecommenders = canCreate?.have_recommenders;
        return this.getWizardStep(formRows, applications, needDocs, haveRecommenders);
      }),
    );
  }

  private getWizardStep(
    formRows: IControlSetting[],
    applications: IApplication[],
    needDocs: boolean,
    hasRecommenders: boolean,
  ): MasterStep {
    if (this.isBankIdStep(formRows)) {
      return MasterStep.BankId;
    } else if (this.validateEmploymentsData(formRows, hasRecommenders)) {
      return MasterStep.Employments;
    } else if (this.validatePersonalData(formRows)) {
      return MasterStep.PersonalData;
    } else if (this.validateAddressData(formRows)) {
      return MasterStep.Address;
    } else if (!applications.length) {
      return MasterStep.Application;
    } else if (applications?.length || needDocs) {
      const application = applications[0];
      return this.getStepByApplicationStatus(application, formRows);
    } else {
      return MasterStep.Employments;
    }
  }

  private validatePersonalData(formRows: IControlSetting[]) {
    const passportTypeControl = formRows.find((item) => item.field === ControlSettingsNameEnum.PassportTypeId);
    const passportType =
      (passportTypeControl?.list ?? [])?.find((item) => item.value == passportTypeControl?.value)?.key ?? null;
    const pc = new Set([...PassportIdControls, ...PassportBookControls]);
    const data = PassportControlsStep.filter((c) => !Array.from(pc)?.includes(c));
    let passportControls: IControlSetting[];
    if (passportType && passportType === PassportTypeEnum.Book) {
      const controls = PassportControlsStep.filter((c) => [...data, ...PassportBookControls].includes(c));
      passportControls = formRows.filter((c) => controls.includes(c.field));
    } else if (passportType && passportType === PassportTypeEnum.IdCard) {
      const controls = PassportControlsStep.filter((c) => [...data, ...PassportIdControls].includes(c));
      passportControls = formRows.filter((c) => controls.includes(c.field));
    } else {
      passportControls = formRows.filter((c) => PassportControlsStep.includes(c.field));
    }
    return this.validateStepForm(passportControls);
  }

  private validateAddressData(formRows: IControlSetting[]): boolean {
    const sameAddress = formRows.find((c) => c.field === ControlSettingsNameEnum.IsActualAddressSameRegistration);
    let controls = [...AddressRegistrationControls];
    if (sameAddress?.value) {
      const fr = formRows.filter((c) => controls.includes(c.field));
      return this.validateStepForm(fr);
    } else {
      controls = [...AddressRegistrationControls, ...AddressActualControls];
      const fr = formRows.filter((c) => controls.includes(c.field));
      return this.validateStepForm(fr);
    }
  }

  private validateEmploymentsData(formRows: IControlSetting[], hasRecommenders: boolean): boolean {
    const socialStatus = formRows.find((c) => c.field === ControlSettingsNameEnum.SocialStatusId);
    const list = socialStatus?.list ?? [];
    const value = list?.find((o) => o.value === socialStatus?.value)?.key ?? null;
    if (!value) return true;
    if (value === SocialStatusEnum.Unemployed) {
      const controlKeys = [...UnemployedControls];
      const controls = formRows.filter((c) => controlKeys.includes(c.field));
      return this.validateStepForm(controls) || !hasRecommenders;
    } else if (value === SocialStatusEnum.Working) {
      const controlKeys = [...WorkingControls];
      const controls = formRows.filter((c) => controlKeys.includes(c.field));
      return this.validateStepForm(controls) || !hasRecommenders;
    }
    return false;
  }

  private getStepByApplicationStatus(application: IApplication, formRows?: IControlSetting[]) {
    if (application.status === ApplicationStatusEnum.Pending && this.isSecretQuestionStep(formRows ?? [])) {
      return MasterStep.SecretQuestion;
    } else if (application.status === ApplicationStatusEnum.Pending) {
      return MasterStep.Waiting;
    } else if (application.status === ApplicationStatusEnum.Approved) {
      return MasterStep.ApplicationApprove;
    } else if (application.status === ApplicationStatusEnum.Rejected) {
      return MasterStep.ApplicationRejected;
    } else if (application.status === ApplicationStatusEnum.SentRevision) {
      return MasterStep.FinalizationApplication;
    } else if (application.status === ApplicationStatusEnum.WaitingMoney) {
      return MasterStep.PaymentWaiting;
    } else {
      return MasterStep.Waiting;
    }
  }

  private validateStepForm(controls: IControlSetting[]) {
    return controls?.some((item) => item?.required && !item.value);
  }

  private isBankIdStep(formRows: IControlSetting[]) {
    return this.validatePersonalData(formRows) && !this.bankIdSecondStep && this.enableBankId;
  }

  private isSecretQuestionStep(formRows: IControlSetting[]) {
    if (!formRows?.length) return false;
    const secreteQuestionId = formRows?.find((el) => el.field === ControlSettingsNameEnum.SecretQuestionId)?.value;
    const secreteQuestionAnswer = formRows?.find((el) => el.field === ControlSettingsNameEnum.SecretAnswer)?.value;
    return !secreteQuestionAnswer || !secreteQuestionId;
  }
}
