import { ControlSettingsNameEnum } from '@core/enums';
import { IControlSetting } from '@core/interfaces';

export const recommenderStaticControls: IControlSetting[] = [
  {
    classificatorType: '',
    field: ControlSettingsNameEnum.FirstName,
    list: [],
    order: 0,
    readonly: false,
    readonlyWhen: [],
    required: true,
    requiredWhen: [],
    targetClass: '',
    type: 1,
    value: null,
  },
  {
    classificatorType: '',
    field: ControlSettingsNameEnum.Surname,
    list: [],
    order: 0,
    readonly: false,
    readonlyWhen: [],
    required: true,
    requiredWhen: [],
    targetClass: '',
    type: 1,
    value: null,
  },
  {
    classificatorType: '14',
    field: ControlSettingsNameEnum.RelationshipId,
    list: [],
    order: 0,
    readonly: false,
    readonlyWhen: [],
    required: true,
    requiredWhen: [],
    targetClass: '',
    type: 1,
    value: null,
  },
  {
    classificatorType: '',
    field: ControlSettingsNameEnum.Phone,
    list: [],
    order: 0,
    readonly: false,
    readonlyWhen: [],
    required: true,
    requiredWhen: [],
    targetClass: '',
    type: 1,
    value: null,
  },
];
