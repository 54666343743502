import { Injectable } from '@angular/core';
import { IResponseData, ISurvey, ISurveyCompleted } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(private readonly apiSvc: ApiService) {}

  list(body = {}): Observable<IResponseData<ISurvey[]>> {
    return this.apiSvc.post('user/survey/list', body);
  }

  fill(body = {}): Observable<void> {
    return this.apiSvc.post('user/survey/fill-survey', body);
  }

  completedList(body = {}): Observable<IResponseData<ISurveyCompleted[]>> {
    return this.apiSvc.post('user/survey-completed/list', body);
  }
}
