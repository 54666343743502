export enum CompareTypeEnum {
  /** Меньше*/
  Less = 1,
  /** Меньше или равно*/
  LessOrEqual = 2,
  /** Больше*/
  Bigger = 3,
  /** Больше или равно*/
  BiggerOrEqual = 4,
  /** Равно*/
  Equal = 5,
  /** Строгое равенство (с учетом типа)*/
  StrictEqualityConsideringOnType = 6,
  /** Не равно */
  NotEqual = 7,
  /** Строгое не равно (с учетом типа) */
  StrictNotEqualConsideringOnType = 8,
  /** Между значениями */
  BetweenValues = 9,
  /** Между значениями или равно одному из них*/
  BetweenValuesOrEqualOneOfThem = 10,
  /** Между значениями или равно левому*/
  BetweenValuesOrEqualToLeft = 11,
  /** Между значениями или равно правому*/
  BetweenValueOrEqualToRight = 12,
  /** Присутствует в списке*/
  PresentInTheList = 13,
  /** Не присутствует в списке*/
  NotPresentInTheList = 14,
  /** Длинна строки более */
  TheLengthOfTheLineIsMoreThan = 15,
  /** Длинна строки менее*/
  TheLengthOfTheLineIsLessThan = 16,
  /** Длинна строки равно*/
  TheLengthOfTheLineIsEqual = 17,
}
