export enum FileControlsType {
  FotoSKartoi = 'Foto_s_kartoi',
  Mfo = 'MFO',
  Zagran1 = 'Zagran1',
  Zagran2 = 'Zagran2',
  Zarplata = 'Zarplata',
  Deposit = 'deposit',
  Inn = 'inn',
  Other = 'other',
  Passport1 = 'passport1',
  Passport2 = 'passport2',
  Passport3 = 'passport3',
  Passport4 = 'passport4',
  PsignInn = 'psign_inn',
  PsignPassport = 'psign_passport',
  Psign_PersonalPhoto = 'psign_personalPhoto',
  PsignZpassport = 'psign_zpassport',
}
