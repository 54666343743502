import { Injectable } from '@angular/core';
import {
  IAuthRepeatCodeRequest,
  IAuthResponse,
  IBankIdRegistration,
  ICompleteRegistration,
  IdData,
  IFormControlsData,
  ILoginRequest,
  IRegistrationRequest,
  IResponseData,
  IUser,
} from '@core/interfaces';
import { map, Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly apiSvc: ApiService) {}

  getUserData(body = {}) {
    return this.apiSvc.post<IResponseData<IUser>>('user/user/get', body).pipe(map((r) => r?.data));
  }

  login(body: ILoginRequest): Observable<IAuthResponse> {
    return this.apiSvc.post('user/user/auth', body);
  }

  registration(body: IRegistrationRequest, ref?: string): Observable<IResponseData<IdData>> {
    let link = 'user/user/registration';
    if (ref) link += `?referral=${ref}`;
    return this.apiSvc.post(link, body);
  }

  getRegistrationConf(body = {}): Observable<IResponseData<IFormControlsData>> {
    return this.apiSvc.post<IResponseData<IFormControlsData>>('user/user/get-registration-form', body);
  }

  remindPasswordCode(body: { phone: string }): Observable<IResponseData<null>> {
    return this.apiSvc.post<IResponseData<null>>('user/user/password-reset-code', body);
  }

  resetPassword(body = {}): Observable<IResponseData<unknown>> {
    return this.apiSvc.post('user/user/password-reset', body);
  }

  verifyAuthCode(body = {}): Observable<IResponseData<ICompleteRegistration>> {
    return this.apiSvc.post('user/user/verify-auth-code', body);
  }

  completeRegistration(body = {}): Observable<IResponseData<ICompleteRegistration>> {
    return this.apiSvc.post('user/user/complete-registration', body);
  }

  bankIdRegistration(body = ''): Observable<IResponseData<unknown>> {
    return this.apiSvc.get('online/bankid-nbu/authorize' + body);
  }

  bankIdAuthGet(body = {}): Observable<IResponseData<IBankIdRegistration>> {
    return this.apiSvc.post('user/integration-bank-id/nbu', body);
  }

  changePhone(body = {}): Observable<IResponseData<unknown>> {
    return this.apiSvc.post('user/user-recovery/add', body);
  }

  getUser(body = {}): Observable<IResponseData<IUser>> {
    return this.apiSvc.post('user/user/get', body);
  }

  repeatAuthCode(body: IAuthRepeatCodeRequest): Observable<IResponseData<void>> {
    return this.apiSvc.post('user/user/repeat-auth-code', body);
  }

  repeatRegistrationCode(body = {}) {
    return this.apiSvc.post('user/user/repeat-registration-code', body);
  }
}
