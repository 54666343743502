import { Injectable } from '@angular/core';
import { ControlSettingsNameEnum } from '@core/enums';
import { IControlSetting, IProfileFormData, IProfileUserName, IResponseData } from '@core/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';
import { ApiService } from './api.service';
import { ClassificatorService } from './classificator.service';
import { ProfileAvatarQuery, ProfileAvatarStore, ProfileDataQuery, ProfileDataStore } from '@core/state';
import { cacheable } from '@datorama/akita';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(
    private readonly apiSvc: ApiService,
    private readonly classificatorSvc: ClassificatorService,
    private readonly translateService: TranslateService,
    private readonly query: ProfileDataQuery,
    private readonly store: ProfileDataStore,
    private readonly avatarQuery: ProfileAvatarQuery,
    private readonly avatarStore: ProfileAvatarStore,
  ) {
  }

  updateProfile(body: Record<string, Record<string, unknown>> | {
    form_fields: Record<string, unknown>
  }): Observable<unknown> {
    return this.apiSvc.post('user/application/fill-application-form', body).pipe(
      tap(() => {
        const fieldsKeys: string[] = Object.keys(body?.form_fields ?? {});
        fieldsKeys.forEach(k => {
          this.store.update(k, { value: body?.form_fields?.[k] });
        });
      }),
    );
  }

  profileFormData(body = {}): Observable<IControlSetting[]> {
    const req$ = this.apiSvc.post<IResponseData<IProfileFormData>>('user/application/get-application-form', body).pipe(
      map((r) => r?.data ?? {}),
      switchMap((data) => {
        const rows = data['form_rows'] ?? [];
        const obsData = rows.map((control) => {
          if (control.classificatorType) return this.classificatorSvc.getControlClassificatorItems(control);
          return of(control);
        });
        return forkJoin(obsData);
      }),
      tap(v => this.store.set(v)),
    );
    return cacheable(this.store, req$, { emitNext: true })
      .pipe(switchMap(resp => resp ? of(resp) : this.query.getFormFields$()));
  }

  userName(): Observable<IProfileUserName> {
    return this.apiSvc.post<IResponseData<IProfileFormData>>('user/application/get-application-form').pipe(
      map((r) => r?.data?.form_rows ?? []),
      map((data) => {
        const firstName = data?.find((c) => c.field === ControlSettingsNameEnum.FirstName)?.value ?? '';
        const lastName = data?.find((c) => c.field === ControlSettingsNameEnum.Surname)?.value ?? '';
        const middleName = data?.find((c) => c.field === ControlSettingsNameEnum.MiddleName)?.value ?? '';
        return { firstName, lastName, middleName } as IProfileUserName;
      }),
    );
  }

  getAvatar(): Observable<string> {
    const req$ = this.apiSvc.post<Blob>('user/user/get-avatar', {}, 'blob')
      .pipe(
        map(blob => URL.createObjectURL(blob)),
        tap(v => {
          this.avatarStore.update({ avatar: v });
          this.avatarStore.setHasCache(true);
        }),
      );

    return cacheable(this.avatarStore, req$, { emitNext: true })
      .pipe(switchMap(resp => resp ? of(resp) : this.avatarQuery.getAvatar$()));

  }

  profileLkFormData(): Observable<Array<IControlSetting>> {
    return this.apiSvc.post<IResponseData<IProfileFormData>>('user/user-profile/get-form').pipe(
      map((r) => r?.data ?? {}),
      switchMap((data) => {
        const rows = data['form_rows'] ?? [];
        const obsData = rows.map((control) => {
          if (control.classificatorType) return this.classificatorSvc.getControlClassificatorItems(control);
          return of(control);
        });
        return forkJoin(obsData);
      }),
    );
  }

  updateLkProfile(body = {}): Observable<IResponseData<unknown>> {
    return this.apiSvc.post('user/user-profile/save', body);
  }
}
