import { Injectable } from '@angular/core';

import { ProfileAvatarState, ProfileAvatarStore } from '@core/state/profile-avatar.store';
import { Query } from '@datorama/akita';


@Injectable({ providedIn: 'root' })
export class ProfileAvatarQuery extends Query<ProfileAvatarState> {

  getAvatar$ = () => this.select(state => state.avatar);

  constructor(protected override store: ProfileAvatarStore) {
    super(store);
  }


}
