import { ControlSettingsNameEnum } from '../enums';
import { IControlSetting } from '../interfaces';

export const loginStaticControls: IControlSetting[] = [
  {
    classificatorType: '',
    field: ControlSettingsNameEnum.Phone,
    list: [],
    order: 0,
    readonly: false,
    readonlyWhen: [],
    required: true,
    requiredWhen: [],
    targetClass: '',
    type: 1,
    value: null,
  },
  {
    classificatorType: '',
    field: ControlSettingsNameEnum.Password,
    list: [],
    order: 0,
    readonly: false,
    readonlyWhen: [],
    required: true,
    requiredWhen: [],
    targetClass: '',
    type: 1,
    value: null,
  },
];

export const registrationStaticControls: IControlSetting[] = [
  ...loginStaticControls,
  {
    classificatorType: '',
    field: ControlSettingsNameEnum.RepeatPassword,
    list: [],
    order: 0,
    readonly: false,
    readonlyWhen: [],
    required: true,
    requiredWhen: [],
    targetClass: '',
    type: 1,
    value: null,
  },
];
