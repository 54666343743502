export enum NotificationSendChanelEnum {
  /** Пуш */
  Push = 1,
  /** Телеграм */
  Telegram = 2,
  /** Смс */
  Sms = 3,
  /** Дзвінок */
  AsteriskCall = 4,
}
