import { Injectable } from '@angular/core';
import { IResponseData } from '@core/interfaces';
import { ApiService } from '@services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CallService {
  constructor(private readonly apiSvc: ApiService) {}

  callMe(body = {}): Observable<IResponseData<unknown>> {
    return this.apiSvc.post('user/user/order-call', body);
  }
}
