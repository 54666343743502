import { Injectable } from '@angular/core';
import { IBitWallet, IResponseData, IWalletAddResponse } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class BitWalletService {
  constructor(private readonly apiSvc: ApiService) {}

  list(body = {}): Observable<IResponseData<IBitWallet[]>> {
    return this.apiSvc.post('user/crypto-hub/wallet-list', body);
  }

  addWallet(body = {}): Observable<IResponseData<IWalletAddResponse>> {
    return this.apiSvc.post('user/crypto-hub/wallet-add', body);
  }

  transfer(body = {}) {
    return this.apiSvc.post('user/crypto-hub/transfer', body);
  }

  requestTransfer(body = {}) {
    return this.apiSvc.post('user/crypto-hub/request-transfer', body);
  }
}
