export const CREDIT_CARD_MASK = '0000 0000 0000 0000';
export const PHONE_MASK = '(000) 000 00 00';
export const PHONE_PREFIX_MASK = '+38 ';

export const PHONE_TEXT_MASK = {
  mask: ['+', '3', '8', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
  guide: false,
};

export const INN_MASK = {
  mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  guide: false,
};

export const PASSPORT_RECORD_MASK = {
  mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/],
  guide: false,
};

export const PASSPORT_NUMBER_MASK = {
  mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  guide: false,
};

export const PASSPORT_ID_NUMBER_MASK = {
  mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  guide: false,
};

export const PASSPORT_ISSUED_BY_NUMBER_MASK = {
  mask: [/\d/, /\d/, /\d/, /\d/],
  guide: false,
};

export const PASSPORT_SERIA_MASK = {
  mask: [/[а-яА-Я]/, /[а-яА-Я]/],
  guide: false,
};

export const AMOUNT_INPUT_MASK = {
  mask: [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  guide: false,
};

export const CREDIT_CARD_TEXT_MASK = {
  mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
  guide: false,
};
