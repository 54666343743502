import { Inject, Injectable } from '@angular/core';
import { STORAGE_CONSTANT } from '@core/constants';
import { ENVIRONMENT } from '@core/environment';
import { IEnvironment, IWsData } from '@core/interfaces';
import { BehaviorSubject, map, Observable, retry } from 'rxjs';
import { WebSocketSubject } from 'rxjs/internal/observable/dom/WebSocketSubject';
import { webSocket } from 'rxjs/webSocket';
import { StorageService } from './storage.service';

@Injectable()
export class WebsocketService {
  private readonly dataObs$ = new BehaviorSubject<IWsData | null>(null);

  constructor(private readonly storageSvc: StorageService, @Inject(ENVIRONMENT) private readonly env: IEnvironment) {}

  get data$(): Observable<IWsData | null> {
    return this.dataObs$.asObservable();
  }

  set data(value: IWsData | null) {
    this.dataObs$.next(value);
  }

  get socketLink(): string {
    return this.env.socket + `?token=${this.token}`;
  }

  private get token(): string {
    return this.storageSvc.get<string>(STORAGE_CONSTANT.TOKEN) ?? '';
  }

  listen<T>(link: string): Observable<T> {
    const ws: WebSocketSubject<T> = webSocket({
      url: link,
      deserializer: (e: MessageEvent) => e.data,
      serializer: (value: T) => JSON.stringify(value),
    });
    return ws.pipe(
      retry({ delay: 3000 }),
      map((d) => {
        try {
          JSON.parse(d as unknown as string);
          return JSON.parse(d as unknown as string) as T;
        } catch (e) {
          return null as T;
        }
      }),
    );
  }
}
