import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IControlSetting } from '@core/interfaces';


export interface ProfileDataState extends EntityState<IControlSetting, string>{
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'ProfileDataStore',
  cache: {
    ttl: 3600000,
  },
  idKey: 'field',
  resettable: true,
})
export class ProfileDataStore extends EntityStore<ProfileDataState> {
  constructor() {
    super();
  }
}
