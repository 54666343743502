export const icons = [
  {
    path: 'assets/image/icons/eye-closed.svg',
    name: 'hidePassword',
  },
  {
    path: 'assets/image/icons/eye-open.svg',
    name: 'showPassword',
  },
  {
    path: 'assets/image/icons/active-stepper-icon.svg',
    name: 'remindPasswordActive',
  },
  {
    path: 'assets/image/icons/done-stepper-icon.svg',
    name: 'remindPasswordDone',
  },
  {
    path: 'assets/image/icons/succes-stepper.svg',
    name: 'remindPasswordSuccess',
  },
  {
    path: 'assets/image/icons/telegram-icon.svg',
    name: 'telegram',
  },
  {
    path: 'assets/image/icons/load-application.svg',
    name: 'loadApplication',
  },
  {
    path: 'assets/image/icons/main-sidebar-icons/credit-calculator.svg',
    name: 'creditCalculator',
  },
  {
    path: 'assets/image/icons/main-sidebar-icons/user-profile.svg',
    name: 'userProfile',
  },
  {
    path: 'assets/image/icons/main-sidebar-icons/user-credits.svg',
    name: 'userCredits',
  },
  {
    path: 'assets/image/icons/main-sidebar-icons/our-shares.svg',
    name: 'ourPurpose',
  },
  {
    path: 'assets/image/icons/main-sidebar-icons/cashback.svg',
    name: 'cashback',
  },
  {
    path: 'assets/image/icons/main-sidebar-icons/referral-program.svg',
    name: 'referralProgram',
  },
  {
    path: 'assets/image/icons/main-logo.svg',
    name: 'mainLogo',
  },
  {
    path: 'assets/image/icons/credit-card.svg',
    name: 'creditCard',
  },
  {
    path: 'assets/image/icons/phone.svg',
    name: 'phone',
  },
  {
    path: 'assets/image/icons/button-add.svg',
    name: 'buttonAdd',
  },
  {
    path: 'assets/image/icons/close-btn-gray.svg',
    name: 'buttonCloseGray',
  },
  {
    path: 'assets/image/icons/delete-icon.svg',
    name: 'deleteIcon',
  },
  {
    path: 'assets/image/icons/delete-dark-icon.svg',
    name: 'deleteDarkIcon',
  },
  {
    path: 'assets/image/icons/credit-card-btn.svg',
    name: 'creditCardBtn',
  },
  {
    path: 'assets/image/icons/main-credit-card-btn.svg.svg',
    name: 'mainCreditCard',
  },
  {
    path: 'assets/image/icons/decision-icon.svg',
    name: 'decisionIcon',
  },
  {
    path: 'assets/image/icons/back-btn-icon.svg',
    name: 'backBtn',
  },
  {
    path: 'assets/image/icons/application-contract-btn-icon.svg',
    name: 'applicationContractBtn',
  },
  {
    path: 'assets/image/icons/credit-card-remove-icon.svg',
    name: 'creditCardRemoveIcon',
  },
  {
    path: 'assets/image/icons/verified-icon.svg',
    name: 'verifiedIcon',
  },
  {
    path: 'assets/image/icons/add-credit-card-btn.svg',
    name: 'addCreditCardBtn',
  },
  {
    path: 'assets/image/icons/remove-credit-card-btn-icon.svg',
    name: 'removeCreditCardBtn',
  },
  {
    path: 'assets/image/icons/email-icon.svg',
    name: 'email',
  },
  {
    path: 'assets/image/icons/loading-icon.svg',
    name: 'loadingIcon',
  },
  {
    path: 'assets/image/icons/copy-icon.svg',
    name: 'copyIcon',
  },
  {
    path: 'assets/image/icons/copy-light-icon.svg',
    name: 'copyIconLight',
  },
  {
    path: 'assets/image/icons/filled-check-icon-light.svg',
    name: 'checkIconFilledLight',
  },
  {
    path: 'assets/image/icons/minus-circle-icon-light.svg',
    name: 'minusCircleIconLight',
  },
  {
    path: 'assets/image/icons/expand-let-icon.svg',
    name: 'expandLeftIcon',
  },
  {
    path: 'assets/image/icons/expand-right-icon.svg',
    name: 'expandRightIcon',
  },
  {
    path: 'assets/image/icons/error-icon.svg',
    name: 'errorIcon',
  },
  {
    path: 'assets/image/icons/info-circle-icon.svg',
    name: 'infoCircleIcon',
  },
  {
    path: 'assets/image/icons/error-dark-icon.svg',
    name: 'errorDarkIcon',
  },
  {
    path: 'assets/image/icons/telegram-dark-icon.svg',
    name: 'telegramDarkIcon',
  },
  {
    path: 'assets/image/icons/application-icon-light.svg',
    name: 'applicationIconLight',
  },
  {
    path: 'assets/image/icons/application-dark-icon.svg',
    name: 'applicationIconDark',
  },
  {
    path: 'assets/image/icons/mobile-main-logo.svg',
    name: 'mobileMainLogo',
  },
  {
    path: 'assets/image/icons/check-input-icon.svg',
    name: 'checkInputIcon',
  },
  {
    path: 'assets/image/icons/bank-id-icon.svg',
    name: 'bankIdIcon',
  },
  {
    path: 'assets/image/icons/button-add-dark-icon.svg',
    name: 'buttonAddDark',
  },
  {
    path: 'assets/image/icons/button-add-red-icon.svg',
    name: 'buttonAddRed',
  },
  {
    path: 'assets/image/icons/add-button-green.svg',
    name: 'buttonAddGreen',
  },
  {
    path: 'assets/image/icons/close-modal-btn.svg',
    name: 'closeModalBtn',
  },
  {
    path: 'assets/image/icons/anchor-link-icon.svg',
    name: 'anchorLink',
  },
  {
    path: 'assets/image/icons/location-icon.svg',
    name: 'locationIcon',
  },
  {
    path: 'assets/image/icons/g-pay-logo.svg',
    name: 'gPayLogoIcon',
  },
  {
    path: 'assets/image/icons/apple-pay-logo.svg',
    name: 'applePayLogoIcon',
  },
  {
    path: 'assets/image/icons/promo-code-invalid-icon.svg',
    name: 'promoCodeInvalidIcon',
  },
  {
    path: 'assets/image/icons/promo-code-valid-icon.svg',
    name: 'promoCodeValidIcon',
  },
  {
    path: 'assets/image/icons/arrow-down.svg',
    name: 'arrowDownSvg'
  }
];
