import { ChangeDetectionStrategy, Component } from '@angular/core';
import { STORAGE_CONSTANT } from '@core/constants';
import { ISettings } from '@core/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FacebookMetaPixelService, IconService, SettingsService, StorageService } from '@services';
import { map, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private readonly iconSvc: IconService,
    private readonly storageSvc: StorageService,
    private readonly settingsSvc: SettingsService,
    private FacebookMetaPixelService: FacebookMetaPixelService,
  ) {
    this.iconSvc.init();
    // this.FacebookMetaPixelService.load();
    // Logic to show/hide mobile app links in project;
    this.storageSvc.set(STORAGE_CONSTANT.AVAILABLE_MOBILE_APP, true);
    this.settingsSvc
      .getSettingsList()
      .pipe(
        map((r) => r?.data),
        tap((d) => this.storageSvc.set<ISettings>(STORAGE_CONSTANT.SETTINGS, d)),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
